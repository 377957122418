import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AnalyticsComponent} from "./analytics/analytics.component";
import {BookingsComponent} from "./bookings/bookings.component";
import {HotelInformationComponent} from "./hotel-information/hotel-information.component";
import { ContactInformationComponent } from './contact-information/contact-information.component';
import { HotelGalleryComponent } from './hotel-gallery/hotel-gallery.component';
import { ServicesComponent } from './services/services.component';
import { PaymentsComponent } from './payments/payments.component';
import { RoomsComponent } from './rooms/rooms.component';
import { RoomEditComponent } from './rooms/room-edit/room-edit.component';
import { AmenitiesComponent } from './amenities/amenities.component';
import { ThemesComponent } from './themes/themes.component';
import { ActivitiesComponent } from './activities/activities.component';
import { MessagesComponent } from './messages/messages.component';
import { UspComponent } from './usp/usp.component';
import { RoomAddComponent } from './rooms/room-add/room-add.component';
import { HotelDescriptionComponent } from './hotel-description/hotel-description.component';
import { HotelManagersComponent } from './hotel-managers/hotel-managers.component';
import { SeoContentComponent } from './seo-content/seo-content.component';
import { HotelSettingsComponent } from './hotel-settings/hotel-settings.component';

const routes: Routes = [
  {
    path: 'analytics',
    component: AnalyticsComponent
  },
  {
    path: 'bookings',
    component: BookingsComponent
  },
  {
    path: 'hotel-information',
    component: HotelInformationComponent
  },
  {
    path: 'hotel-description',
    component: HotelDescriptionComponent
  },
  {
    path: 'contact-information',
    component: ContactInformationComponent
  },
  {
    path: 'hotel-gallery',
    component: HotelGalleryComponent
  },
  {
    path: 'services',
    component: ServicesComponent
  },
  {
    path: 'usp',
    component: UspComponent
  },
  {
    path: 'payments',
    component: PaymentsComponent
  },
  {
    path: 'rooms',
    component: RoomsComponent
  },
  { path: 'add-room',
    component: RoomAddComponent
  },
  { path: 'room-edit/:id',
    component: RoomEditComponent
  },
  {
    path: 'amenities',
    component: AmenitiesComponent
  },
  {
    path: 'themes',
    component: ThemesComponent
  },
  {
    path: 'activities',
    component: ActivitiesComponent
  },
  {
    path: 'messages',
    component: MessagesComponent
  },
  {
    path: 'hotel-managers',
    component: HotelManagersComponent
  },
  {
    path: 'seo-content',
    component: SeoContentComponent
  },
  {
    path: 'hotel-settings',
    component: HotelSettingsComponent
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
