<div *ngIf="error" class="alert alert-danger alert-dismissible fade show mt-3">
  <i class="uil uil-exclamation-triangle me-2"></i>
  <span class="ms-2">
    {{ error }}
  </span>
  <button type="button" class="btn-close" aria-label="Close" (click)="error = ''"></button>
</div>
<div *ngIf="success" class="alert alert-success alert-dismissible fade show mt-3">
  <i class="uil uil-check me-2"></i>
  <span class="ms-2">
    {{ success }}
  </span>
  <button type="button" class="btn-close" aria-label="Close" (click)="success = ''"></button>
</div>
<div class="container-fluid mt-3">
    <!-- start page title -->
    <div class="d-flex justify-content-between align-items-center">
        <app-pagetitle title="Edit Room"></app-pagetitle>
        <!-- Breadcrumb navigation positioned to the right -->
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb justify-content-end mb-0">
            <li *ngFor="let item of breadCrumbItems; let last = last" class="breadcrumb-item" [ngClass]="{ 'active': item.active }">
              <ng-container *ngIf="!last">
                <!-- Non-active breadcrumb items with routerLink -->
                <a [routerLink]="item.link">{{ item.label }}</a>
              </ng-container>
              <ng-container *ngIf="last">
                <!-- Active breadcrumb (the last item) with no link -->
                {{ item.label }}
              </ng-container>
            </li>
          </ol>
        </nav>
      </div>
    <!-- end page title -->
    <div class="card mt-2">
        <div class="card-body">
            <form (ngSubmit)="onSubmit(roomForm)" #roomForm="ngForm" enctype="multipart/form-data">
                <div class="row mb-3">
                    <label for="roomName" class="form-label col-2">Room Description</label>
                    <div class="col-8">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <div class="d-flex mb-2">
                                        <span class="px-3 py-2 text-room-color form-input-bg">Duetsch</span>
                                        <input type="email" class="form-control form-input-bg text-room-desc"  name="name_de" [(ngModel)]="room.name.de" #name_de="ngModel" required>
                                    </div>
                                    <textarea class="form-control form-input-bg textarea-room"
                                        name="description_de" [(ngModel)]="room.description.de" #description_de="ngModel"
                                        placeholder="Room description"
                                        required> {{ room.description.de }}
                                    </textarea>
                                    <div *ngIf="description_de.invalid && (description_de.dirty || description_de.touched)" class="text-danger">
                                      <div *ngIf="description_de.errors?.['required']">Hotel description in Duetsch is required.</div>
                                    </div>
                                    <div *ngIf="name_de.invalid && (name_de.dirty || name_de.touched)" class="text-danger">
                                      <div *ngIf="name_de.errors?.['required']">Hotel name in Duetsch is required.</div>
                                    </div>
                                </div>
                            </div><!-- end col -->
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <div class="d-flex">
                                        <span class="px-3 py-2 text-room-color form-input-bg">Francais</span>
                                        <input type="email" class="form-control form-input-bg text-room-desc" name="name_fr" [(ngModel)]="room.name.fr" #name_fr="ngModel" required>
                                    </div>
                                    <textarea class="form-control form-input-bg textarea-room"
                                            name="description_fr" [(ngModel)]="room.description.fr" #description_fr="ngModel"
                                            placeholder="Room description"
                                            required>{{ room.description.fr }}
                                    </textarea>
                                    <div *ngIf="description_fr.invalid && (description_fr.dirty || description_fr.touched)" class="text-danger">
                                      <div *ngIf="description_fr.errors?.['required']">Hotel description in Duetsch is required.</div>
                                    </div>
                                    <div *ngIf="name_fr.invalid && (name_fr.dirty || name_fr.touched)" class="text-danger">
                                      <div *ngIf="name_fr.errors?.['required']">Hotel name in Duetsch is required.</div>
                                    </div>
                                </div>
                            </div><!-- end col -->
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <div class="d-flex">
                                        <span class="px-3 py-2 text-room-color form-input-bg">English</span>
                                        <input type="email" class="form-control form-input-bg text-room-desc" name="name_en" [(ngModel)]="room.name.en" #name_en="ngModel" required>
                                    </div>
                                    <textarea class="form-control form-input-bg textarea-room"
                                        name="description_en" [(ngModel)]="room.description.en" #description_en="ngModel"
                                        placeholder="Room description"
                                        required> {{ room.description.en }}
                                    </textarea>
                                    <div *ngIf="description_en.invalid && (description_en.dirty || description_en.touched)" class="text-danger">
                                      <div *ngIf="description_en.errors?.['required']">Hotel description in English is required.</div>
                                    </div>
                                    <div *ngIf="name_en.invalid && (name_en.dirty || name_en.touched)" class="text-danger">
                                      <div *ngIf="name_en.errors?.['required']">Hotel name in English is required.</div>
                                    </div>
                                </div>
                            </div><!-- end col -->
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <div class="d-flex">
                                        <span class="px-3 py-2 text-room-color form-input-bg">Italiano</span>
                                        <input type="email" class="form-control form-input-bg text-room-desc" name="name_it" [(ngModel)]="room.name.it" #name_it="ngModel" required>
                                    </div>
                                    <textarea class="form-control form-input-bg textarea-room"
                                        name="description_it" [(ngModel)]="room.description.fr" #description_it="ngModel"
                                        placeholder="Room description"
                                        required> {{ room.description.it }}
                                    </textarea>
                                    <div *ngIf="description_it.invalid && (description_it.dirty || description_it.touched)" class="text-danger">
                                      <div *ngIf="description_it.errors?.['required']">Hotel description in Italiano is required.</div>
                                    </div>
                                    <div *ngIf="name_it.invalid && (name_it.dirty || name_it.touched)" class="text-danger">
                                      <div *ngIf="name_it.errors?.['required']">Hotel name in Italiano is required.</div>
                                    </div>
                                </div>
                            </div><!-- end col -->
                        </div><!-- end row -->
                    </div>
                    <div class="col-2">
                        <button type="button" class="btn btn-link p-0">
                            <i class="fas fa-trash-alt delete-icon-danger"></i> <!-- Red delete icon -->
                        </button>
                        <img src="assets/images/drag.png" alt="drag-icon" class="img-fluid ms-2">
                    </div>
                </div>
                <hr>
                <div class="row mb-3">
                    <label for="roomName" class="form-label col-2">Room Size</label>
                    <div class="col-10">
                        <input type="email" class="form-control form-input-bg text-room-desc text-room-size" name="average_room_size" [(ngModel)]="room.average_room_size" #average_room_size="ngModel" required>
                    </div>
                </div>
                <div *ngIf="average_room_size.invalid && (average_room_size.dirty || average_room_size.touched)" class="text-danger">
                  <div *ngIf="average_room_size.errors?.['required']">Hotel name in Italiano is required.</div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-2">
                    <h4 class="card-title">Amenities</h4>
                  </div>
                  <div class="col-10">
                    <div class="row">
                      <!-- Select All Column -->
                      <div class="col-auto">
                        <div class="form-check text-room-desc form-room-check" id="categories-pill">
                          <input
                            class="form-check-input cursor-pointer"
                            type="checkbox"
                            [id]="'checkAll'"
                            (change)="toggleSelectAll()"
                            [checked]="selectAll"
                            [ngClass]="{'active': selectAll, 'inactive': !selectAll}"
                            style="display: none;">
                          <label
                            class="form-check-label cursor-pointer categories categories-pill"
                            [for]="'checkAll'"
                            [ngClass]="{'active': selectAll, 'inactive': !selectAll}"
                          >
                            Select all
                          </label>
                        </div>
                      </div>
                      <!-- Amenities List Column -->
                      <div class="col-12 col-md-10">
                        <div class="row">
                          <div class="col-auto" *ngFor="let amenity of amenities; let index = index;">
                            <div class="form-check text-room-desc form-room-check" id="categories-pill">
                              <input
                                class="form-check-input cursor-pointer"
                                type="checkbox"
                                [id]="'check' + index"
                                [(checked)]="amenity.is_active"
                                (change)="toggleActivityStatus(amenity)"
                                [ngClass]="{'active': amenity.is_active, 'inactive': !amenity.is_active}"
                                style="display: none;"
                                name="amenities" [(ngModel)]="room.amenities" #amenities="ngModel" required>

                              <label
                                class="form-check-label cursor-pointer categories"
                                [ngClass]="{'active': amenity.is_active, 'inactive': !amenity.is_active}"
                                [for]="'check' + index">
                                {{ amenity.name }}
                              </label>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div *ngIf="amenities.length < 3" class="text-danger">
                  Rooms per day on HotelCard required.
                </div> -->
                <hr>
                <div class="row mb-3">
                    <label for="roomName" class="form-label col-2">Availability</label>
                    <div class="col-10">
                        <span class="text-room-color">Rooms per day on HotelCard</span>
                        <span class="d-flex">
                            <input class="form-control form-input-bg availability-desc cursor-pointer" type="number" name="amount" [(ngModel)]="room.amount" #amount="ngModel">
                        </span>
                    </div>
                </div>
                <div *ngIf="amount.invalid && (amount.dirty || amount.touched)" class="text-danger">
                  <div *ngIf="amount.errors?.['required']">Rooms per day on HotelCard required.</div>
                </div>
                <hr>
                <div class="row mb-3">
                    <label for="roomName" class="form-label col-2">Occupancy</label>
                    <div class="col-10">
                        <span class="text-room-color">Max no of adults</span>
                        <span class="d-flex">
                            <input class="form-control form-input-bg occupancy-desc cursor-pointer" type="number" name="max_people_allowed" [(ngModel)]="room.max_people_allowed" #max_people_allowed="ngModel" required>
                            <div class="form-check text-room-desc ms-5">
                                <input class="form-check-input cursor-pointer" type="checkbox" name="is_family_room" [(ngModel)]="room.is_family_room" #is_family_room="ngModel" [checked]="room.is_family_room">
                                <label class="form-check-label cursor-pointer" for="autoSizingCheckFamily">
                                    Family Room
                                </label>
                            </div>
                        </span>
                    </div>
                </div>
                <div *ngIf="max_people_allowed.invalid && (max_people_allowed.dirty || max_people_allowed.touched)" class="text-danger">
                  <div *ngIf="max_people_allowed.errors?.['required']">Max no of adults count required.</div>
                </div>
                <hr>
                <div class="row mb-3">
                    <label for="roomName" class="form-label col-2">Extra Beds</label>
                    <div class="col-10">
                        <select class="form-select extra-bed form-input-bg text-room-desc cursor-pointer"
                        name="max_extra_bed_allowed" [(ngModel)]="room.max_extra_bed_allowed" #max_extra_bed_allowed="ngModel">
                            <option [selected]="room.max_extra_bed_allowed == 0" value="0">Select the bed</option>
                            <option [selected]="room.max_extra_bed_allowed == 1" value="1" >1</option>
                            <option [selected]="room.max_extra_bed_allowed == 2" value="2" >2</option>
                        </select>
                    </div>
                </div>
                <hr>
                <div class="row mb-3">
                    <label for="roomName" class="form-label col-2">Base Price</label>
                    <div class="col-10">
                        <input type="email" class="form-control form-input-bg text-room-desc text-room-size" [(ngModel)]="room.base_price" #base_price="ngModel" name="base_price" required>
                        <div *ngIf="base_price.invalid && (base_price.dirty || base_price.touched)" class="text-danger">
                          <div *ngIf="base_price.errors?.['required']">Hotel base price is required.</div>
                        </div>
                        <div class="form-check form-switch form-switch-md mb-2 mt-1 text-room-desc" dir="ltr">
                            <input type="checkbox" class="form-check-input cursor-pointer" name="is_breakfast_included" #is_breakfast_included="ngModel" [checked]="room.is_breakfast_included" [(ngModel)]="room.is_breakfast_included">
                            <label class="form-check-label cursor-pointer label-switch-text position-relative" for="customSwitchsizemd">Breakfast included</label>
                        </div>
                        <div class="form-check text-room-desc">
                            <input class="form-check-input cursor-pointer" type="checkbox" name="allow_single_use" [(ngModel)]="room.allow_single_use" #name="ngModel" [checked]="room.allow_single_use">
                            <label class="form-check-label cursor-pointer" for="formCheck1">
                                Single use
                            </label>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="row mb-3">
                    <label for="roomName" class="form-label col-2">Room images</label>
                    <div class="col-10">
                        <input type="file" id="file" [accept]="'image/*'" (change)="onFileSelect($event)">
                    </div>
                </div>
                <div class="form-check form-switch form-switch-md mb-2 mt-1 text-room-desc position-absolute hide-room-switch" dir="ltr">
                    <label class="form-check-label mt-1 cursor-pointer label-switch-text position-relative" for="customHideSwitchsizemd">Hide room</label>
                    <input type="checkbox" class="form-check-input position-relative form-hide-switch cursor-pointer" name="hide" [(ngModel)]="room.hide" #hide="ngModel" [checked]="room.hide">
                </div>
                <button type="submit" class="btn btn-primary submit_btn float-md-end cursor-pointer" [disabled]="roomForm.invalid">Update room details</button>

            </form>
        </div><!-- end card-body -->
    </div><!-- end card -->
  </div>
