import {SidebarMenu} from "../../core/interfaces/sidebar-menu";

export const MENU: SidebarMenu[] = [
  {
    id: 1,
    label: 'MENUITEMS.DASHBOARDS.TEXT',
    isTitle: true
  },
  {
    id: 2,
    label: 'MENUITEMS.ANALYTICS.TEXT',
    icon: '../../assets/images/Analytics.svg',
    link: '/analytics'
  },
  {
    id: 3,
    label: 'MENUITEMS.BOOKINGS.TEXT',
    isTitle: true
  },
  {
    id: 3,
    label: 'MENUITEMS.BOOKINGS.TEXT',
    icon: '../../assets/images/list-check.svg',
    link: '/bookings',
  },
  {
    id: 4,
    label: 'MENUITEMS.AVAILABILITY.TEXT',
    icon: '../../assets/images/availability.svg',
    link: '/availability',
  },
  {
    id: 5,
    label: 'MENUITEMS.YIELDING.TEXT',
    icon: '../../assets/images/yielding.svg',
    link: '/yielding',
  },
  {
    id: 6,
    label: 'MENUITEMS.EXTRA_SERVICE.TEXT',
    icon: '../../assets/images/extra_service.svg',
    link: '/services',
  },
  {
    id: 7,
    label: 'MENUITEMS.PAYMENTS.TEXT',
    icon: '../../assets/images/payments.svg',
    link: '/payments',
  },
  {
    id: 14,
    label: 'MENUITEMS.MESSAGES.TEXT',
    icon: '../../assets/images/messages.svg',
    link: '/messages',
  },
  {
    id: 8,
    label: 'MENUITEMS.MANAGE_HOTEL.TEXT',
    isTitle: true
  },
  {
    id: 9,
    label: 'MENUITEMS.CONTACT.TEXT',
    icon: '../../assets/images/contact.svg',
    link: '/contact-information',
  },
  {
    id: 10,
    label: 'MENUITEMS.INFO.TEXT',
    icon: '../../assets/images/info.svg',
    link: '/hotel-information',
  },
  {
    id: 11,
    label: 'MENUITEMS.DESC.TEXT',
    icon: '../../assets/images/description.svg',
    link: '/hotel-description',
  },
  {
    id: 12,
    label: 'MENUITEMS.IMAGES.TEXT',
    icon: '../../assets/images/gallery.svg',
    link: '/hotel-gallery',
  },
  {
    id: 13,
    label: 'MENUITEMS.ROOMS.TEXT',
    icon: '../../assets/images/rooms.svg',
    link: '/rooms',
  },
  {
    id: 14,
    label: 'MENUITEMS.FEATURES.LIST.AMENITIES.TEXT',
    icon: '../../assets/images/amenities.svg',
    link: '/amenities',
  },
  {
    id: 15,
    label: 'MENUITEMS.FEATURES.LIST.ACTIVITIES.TEXT',
    icon: '../../assets/images/activities.svg',
    link: '/activities',
  },
  {
    id: 16,
    label: 'MENUITEMS.FEATURES.LIST.THEMES.TEXT',
    icon: '../../assets/images/themes.svg',
    link: '/themes',
  },
  {
    id: 17,
    label: 'MENUITEMS.ADMIN.TEXT',
    isTitle: true,
    admin: true
  },
  {
    id: 18,
    label: 'MENUITEMS.CONFIG.TEXT',
    icon: '../../assets/images/settings.svg',
    link: '/hotel-settings',
    admin: true
  },
  {
    id: 19,
    label: 'MENUITEMS.HOTEL_MANAGER.TEXT',
    icon: '../../assets/images/manager.svg',
    link: '/hotel-managers',
    admin: true
  },
  {
    id: 20,
    label: 'MENUITEMS.USPS.TEXT',
    icon: '../../assets/images/usp.svg',
    link: '/usp',
    admin: true
  },
  {
    id: 23,
    label: 'MENUITEMS.SEO.TEXT',
    icon: '../../assets/images/SEO.svg',
    link: '/seo-content',
    admin: true
  },
];

