import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { CookiesService } from './cookies.service';
import { Hotel } from '../interfaces/hotel';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {
  private selectedHotelKey = 'selectedHotel';
  private selectedHotelSubject = new BehaviorSubject<Hotel | null>(null);
  selectedHotel$ = this.selectedHotelSubject.asObservable();
  public selectedHotelId: number = 0;

  constructor(
    private http: HttpService,
    private cookieService: CookiesService
  ) { }

  getSelectedHotel(): string | null {
    return this.cookieService.get(this.selectedHotelKey);
  }

  getGallery() {
    const selectedHotelId = this.getSelectedHotel();
    return this.http.get(`v2/hotels/images/${selectedHotelId}`);
  }

  // saveGallery(data: any) {
  //   const selectedHotelId = this.getSelectedHotel();
  //   return this.http.put(`v2/hotels/images/${selectedHotelId}`, data);
  // }
  saveGallery(images: any[]): Observable<any> {
    const url = 'https://staging.hotelcard.com/api/v2/hotels/images';
    console.log('Sending request to:', url);  // Log the URL
    return this.http.post(url, { images });
  }

  deleteGallery(imageId: number) {
    const selectedHotelId = this.getSelectedHotel();
    const params = new HttpParams().set('image_ids[]', imageId.toString());
    return this.http.delete(`v2/hotels/images/${selectedHotelId}`, { params });
  }
}
