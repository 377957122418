import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { HttpService } from './http.service';
import { tap, catchError, map } from 'rxjs/operators';
import { Hotel } from '../interfaces/hotel';
import { CookiesService } from './cookies.service';

@Injectable({
  providedIn: 'root'
})
export class ContactInformationService {
  private showLightCardSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private selectedHotelKey = 'selectedHotel';
  private selectedHotelSubject = new BehaviorSubject<Hotel | null>(null);
  selectedHotel$ = this.selectedHotelSubject.asObservable();
  public selectedHotelId: number = 0;


  constructor(
    private http: HttpService,
    private cookieService: CookiesService
  ) { }

  getSelectedHotel(): string | null {
    return this.cookieService.get(this.selectedHotelKey);
  }

  setShowLightCard(show: boolean): void {
    this.showLightCardSubject.next(show);
  }

  getShowLightCard(): Observable<boolean> {
    return this.showLightCardSubject.asObservable();
  }

  getHotelContact() {
    const selectedHotelId = this.getSelectedHotel();
    return this.http.get(`v2/hotels/contact/${selectedHotelId}`);
  }
  saveHotelContact(data: any){
    console.log(data);
    const selectedHotelId = this.getSelectedHotel();
    return this.http.put(`v2/hotels/contact/${selectedHotelId}`, data).pipe(
      map(response => {
        if (response && response.data) {
          return response;
        } else {
          return null;
        }
      }),
      catchError(error => {
        console.error('Error fetching selected hotel contact', error);
        return of(null);
      })
    );
  }
}
