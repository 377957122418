<div *ngIf="error" class="alert alert-danger alert-dismissible fade show mt-3">
    <i class="uil uil-exclamation-triangle me-2"></i>
    <span class="ms-2">
      {{ error }}
    </span>
    <button type="button" class="btn-close mt-1" aria-label="Close" (click)="dismissError()"></button>
</div>

<div *ngIf="successMessage" class="alert alert-success alert-dismissible fade show mt-3">
    <i class="uil uil-check me-2"></i>
    <span class="ms-2">
      {{ successMessage }}
    </span>
    <button type="button" class="btn-close mt-1" aria-label="Close" (click)="dismissSuccess()"></button>
</div>
<!-- start page title -->
<app-pagetitle title="Hotel managers" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->
<form #managerForm="ngForm" (ngSubmit)="onSubmit(managerForm)">
    <div class="row mt-3">
      <!-- Added Users Section -->
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <div class="card card-h-100">
          <div class="card-body card-manager-body">
            <div class="row">
              <div class="col-md-4">
                <label class="available-users" for="available-users">Available users</label>
              </div>
              <div class="col-md-8">
                <input type="text" class="form-control form-input-bg"
                    id="available-users" name="available-users"
                    [(ngModel)]="searchTerm"
                    (ngModelChange)="onSearchChange()"
                    placeholder="Search by name" required />
                <div class="mt-2">
                  <label for="managers-list" class="managers-list mb-16">Users added lately:</label>
                  <ul class="p-0">
                    <li *ngFor="let user of filteredUsers" class="text-decoration-none mb-16 managers-list managers-added-list">
                      {{ user.name }} ({{ user.id }})
                      <img [src]="addManagerImageUrl" alt="Add manager" (click)="addAssignedUser(user)"
                      class="cursor-pointer">
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div><!-- end card -->
      </div><!-- end col -->

      <!-- Assigned Users Section -->
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <div class="card card-h-100">
          <div class="card-body card-manager-body">
            <div class="row">
              <div class="col-md-6">
                <label class="assigned-users" for="assigned-users">Assigned users</label>
              </div>
              <div class="col-md-6">
                <label *ngIf="assignedUsers.length === 0" for="no-users" class="no-users">No users selected.</label>
                <span *ngFor="let user of assignedUsers" class="assigned-user">
                    <div class="row">
                      <div class="col-md-8" id="add-manageres-pill">
                        <input
                        class="form-check-input cursor-pointer"
                        type="checkbox"
                        name="selectedUsers"
                        [id]="'check' + user.id"
                        style="display: none;">
                        <label
                              class="form-check-label cursor-pointer categories"
                        >
                            {{ user.name }} ({{ user.id }})
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" (click)="removeAssignedUser(user)">
                              <path d="M11.5 11.5L4.5 4.5M11.5 4.5L4.5 11.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </label>
                      </div>
                    </div>
                  </span>
              </div>
            </div>
          </div>
        </div><!-- end card -->
      </div><!-- end col -->
    </div><!-- end row -->

    <div class="mt-4">
      <button type="button" class="btn btn-link text-decoration-none cancel_btn float-end cancel_btn_hotel_managers w-md">Cancel</button>
      <button type="submit" class="btn btn-primary float-md-end submit_btn submit_btn_border submit-manager-btn position-absolute w-md d-flex justify-content-center align-items-center">Save changes</button>
   </div>
    <br>
</form>
