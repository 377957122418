import { Injectable, Inject, InjectionToken } from '@angular/core';
import { HttpService } from './http.service';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { CookiesService } from './cookies.service';
import { Hotel } from '../interfaces/hotel';

@Injectable({
  providedIn: 'root'
})
export class FeatureService {
  private selectedHotelKey = 'selectedHotel';
  private selectedHotelSubject = new BehaviorSubject<Hotel | null>(null);
  selectedHotel$ = this.selectedHotelSubject.asObservable();
  public selectedHotelId: number = 0;

  constructor(
    private http: HttpService,
    private cookieService: CookiesService
  ) { }

  getSelectedHotel(): string | null {
    return this.cookieService.get(this.selectedHotelKey);
  }

  getFeatures() {
    return this.http.get(`v2/features`);
  }

  getAmenities() {
    return this.http.get(`v2/features/amenities`);
  }

  getActivities() {
    return this.http.get(`v2/features/activities`);
  }

  getHotelActivities() {
    const selectedHotelId = this.getSelectedHotel();
    return this.http.get(`v2/hotels/activities/${selectedHotelId}`);
  }

  saveActivities(activities: any) {
    const selectedHotelId = this.getSelectedHotel();
    return this.http.put(`v2/hotels/activities/${selectedHotelId}`, activities).pipe(
      map(response => {
        if (response && response.data) {
          return response;
        } else {
          return null;
        }
      }),
      catchError(error => {
        console.error('Error fetching selected hotel bookings', error);
        return of(null);
      })
    );
  }

  getThemes() {
    return this.http.get(`v2/features/themes`);
  }

  getHotelThemes() {
    const selectedHotelId = this.getSelectedHotel();
    return this.http.get(`v2/hotels/themes/${selectedHotelId}`);
  }

  saveThemes(themes: any) {
    const selectedHotelId = this.getSelectedHotel();
    return this.http.put(`v2/hotels/themes/${selectedHotelId}`, themes).pipe(
      map(response => {
        if (response && response.data) {
          return response;
        } else {
          return null;
        }
      }),
      catchError(error => {
        console.error('Error fetching selected hotel bookings', error);
        return of(null);
      })
    );
  }

  saveFeatures(activities: any, id: number) {
    const selectedHotelId = this.getSelectedHotel();
    return this.http.put(`v1/hotelsV2/features/${selectedHotelId}/${id}`, activities).pipe(
      map(response => {
        if (response && response.data) {
          return response;
        } else {
          return null;
        }
      }),
      catchError(error => {
        console.error('Error fetching selected hotel bookings', error);
        return of(null);
      })
    );
  }

}
