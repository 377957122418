import { Component, OnInit } from '@angular/core';
import { HotelService } from '../../core/services/hotel.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MessageService } from '../../core/services/message.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {
  breadCrumbItems!: Array<{}>;
  hotel: any = {};
  messages: any[] = [];
  permanentMessagesCount = 0;
  successMessage = '';
  error = '';
  isTemporaryMessageDisabled = false;
  isTemporaryMessageCount = 0;
  selectedLanguage = 'de';
  currentDateTime: string = this.formatCurrentDateTime();
  dynamicId = 'example-daterange-picker-' + Math.random().toString(36).substring(2, 15);
  calendarIconSrc = 'assets/images/calendar.png';
  value = 0;
  index = null;
  isTemporaryMessageOverlap = false;
  permanentCheck = 0;

  languages = [
    { key: 1, code: 'de', name: 'Deutsch' },
    { key: 2, code: 'en', name: 'English' },
    { key: 3, code: 'fr', name: 'Français' },
    { key: 4, code: 'it', name: 'Italiano' }
  ];

  message = {
    display: 'permanent',
    tempDateRange: { startDate: '', endDate: '' }
  };

  displayOptions = [
    { label: 'Permanent', value: 'permanent' },
    { label: 'Temporary', value: 'temporary' }
  ];

  constructor(
    private hotelService: HotelService,
    private router: Router,
    private datePipe: DatePipe,
    private messageService: MessageService

  ) {}

  ngOnInit(): void {
    this.initializeBreadcrumbs();
    this.subscribeToSelectedHotel();
    this.loadMessages();
  }

  // Initialize breadcrumbs for navigation
  initializeBreadcrumbs(): void {
    this.breadCrumbItems = [
      { label: 'Hotel' },
      { label: 'Messages', active: true }
    ];
  }
  // Subscribe to selected hotel data
  subscribeToSelectedHotel(): void {
    this.hotelService.selectedHotel$.subscribe((hotel: any) => {
      if (hotel) {
        this.hotel = hotel;
        this.subscribeToComments();
      }
    });
  }

  // Subscribe to selected hotel data
  subscribeToComments(): void {
    this.messageService.getMessages().subscribe( result => {
      if (result) {
        this.messages = result.data.comments;
        this.permanentCheck = result.data.is_permanent_message;
        console.log(this.messages);
      }
    });
  }

  focusInput(dateInput: HTMLInputElement): void {
    dateInput.focus();
  }

  // Load messages from localStorage
  loadMessages(): void {
      try {
        this.messages.forEach((message: any) => {
          this.processMessage(message);
        });
      } catch (error) {
        console.error('Error parsing stored messages from localStorage:', error);
        this.messages = [];
      }
    this.checkPermanentMessage(this.value, this.index);
  }

  onSubmit(form: NgForm): void {
    if (form.valid) {

      this.saveMessages();
      this.showSuccess('Form submitted successfully');
    } else {
      this.showError('Form is invalid');
    }
  }

  // Process each message, handling the tempDateRange field
  processMessage(message: any): void {
    if (message.display === 'temporary' && message.tempDateRange) {
      if (typeof message.tempDateRange === 'string' && message.tempDateRange.includes(' - ')) {
        message.tempDateRange = this.convertToDateRangeObject(message.tempDateRange); // Convert to object if it's a string
      }
    }
  }

  addMessage(): void {
    const uniqueId = 'example-daterange-picker-' + Math.random().toString(36).substring(2, 15);
    let newMessage: any;
    this.permanentMessagesCount = this.messages.filter(m => m.display === 'permanent').length;
    if (this.permanentMessagesCount === 0) {
      newMessage = this.createMessage('permanent');
      newMessage.dynamicId = uniqueId;
      this.messages.push(newMessage);
      this.isTemporaryMessageDisabled = true;
    } else {
      // Add a temporary message
      newMessage = this.createMessage('temporary');
      if (newMessage.tempDateRange?.startDate && newMessage.tempDateRange?.endDate) {
        newMessage.tempDateRange = this.formatDateRange(newMessage.tempDateRange); // Ensure proper format
      }
      // Check for overlaps with existing temporary messages
      if (this.isDateRangeOverlapping(newMessage.tempDateRange)) {
        this.isTemporaryMessageOverlap = true;
        this.error = 'Temporary messages cannot overlap in time.';
        setTimeout(() => {
          this.error = '';
        }, 2000);
        return;
      }
      newMessage.dynamicId = uniqueId;
      this.messages.push(newMessage);
      this.isTemporaryMessageDisabled = true;
    }
    this.checkPermanentMessage(this.value, this.index);
  }


  // Create a new message (permanent or temporary)
  createMessage(displayType: 'permanent' | 'temporary'): any {
    let formattedDateRange = '';
    if (this.message.tempDateRange?.startDate && this.message.tempDateRange?.endDate) {
      formattedDateRange = this.formatDateRange(this.message.tempDateRange);
    }

    return {
      deutsch: '',
      deutschDescription: '',
      french: '',
      frenchDescription: '',
      english: '',
      englishDescription: '',
      italian: '',
      italianDescription: '',
      display: displayType,
      isVisible: true,
      tempDateRange: formattedDateRange
    };
  }

  // Check the number of permanent messages and show appropriate errors
  checkPermanentMessage(value: any, index: any): void {
    this.permanentMessagesCount = this.messages.filter(m => m.display === 'permanent').length;
    this.isTemporaryMessageCount = this.messages.filter(m => m.display === 'temporary').length;

    // If there is one or more permanent messages, disable temporary message creation
    if (this.permanentMessagesCount >= 1) {
      this.isTemporaryMessageDisabled = true;

      // If trying to add another permanent message, show error
      if (value === 'permanent') {
        this.messages[index].tempDateRange = null;
        this.isTemporaryMessageDisabled = true;
        this.error = 'No additional messages allowed after setting a message to permanent.';
        setTimeout(() => {
          this.error = '';
        }, 2000);
        return;
      }
    } else {
      // If there are no permanent messages, allow temporary message creation
      this.isTemporaryMessageDisabled = false;
    }

    // Handle the case where a temporary message is being added and check for overlaps
    if (value === 'temporary') {
      if (this.messages[index].tempDateRange === null) {
        this.messages[index].tempDateRange = null;
      }
      const newMessage = this.createMessage('temporary');
      if (this.isDateRangeOverlapping(newMessage.tempDateRange)) {
        this.isTemporaryMessageOverlap = true;
        this.error = 'Temporary messages cannot overlap in time.';
        this.messages[index].tempDateRange = null;
        setTimeout(() => {
          this.error = '';
        }, 2000);
        return;
      }
    }
  }

  // Delete a message
  deleteMessage(index: number): void {
    const messageToDelete = this.messages[index];
    this.messages.splice(index, 1);
    this.saveMessages();
    if (messageToDelete.display === 'permanent') {
      this.isTemporaryMessageDisabled = false;
    }
    this.loadMessages();
  }

  // Save the current messages to localStorage
  saveMessages(): void {
    // Check if there is more than one permanent message before saving
    const permanentMessages = this.messages.filter(m => m.display === 'permanent');
    if (permanentMessages.length > 1) {
      this.showError('Only one permanent message can be added.');
      return;
    }
    // Check for duplicate or overlapping temporary messages
    const duplicateError = this.checkForDuplicateTemporaryMessages();
    if (duplicateError) {
      this.showError(duplicateError);
      return;
    }
    this.messages.forEach(message => {
      if (message.tempDateRange && message.tempDateRange.startDate && message.tempDateRange.endDate) {
        message.tempDateRange = this.formatDateRange(message.tempDateRange);
      }
    });

    this.showSuccess('Message saved successfully');
        // Save messages via ManagerService instead of localStorage
        this.messageService.saveMessages(this.messages).subscribe({
          next: () => {
            this.showSuccess('Message saved successfully');
            this.loadMessages();
          },
          error: (err) => {
            console.error('Error saving messages:', err);
            this.showError('Error saving messages');
          }
        });
    this.loadMessages();
  }

  checkForDuplicateTemporaryMessages(): string | null {
    const temporaryMessages = this.messages.filter(message => message.display === 'temporary');

    for (let i = 0; i < temporaryMessages.length; i++) {
      const currentMessage = temporaryMessages[i];
      for (let j = i + 1; j < temporaryMessages.length; j++) {
        const compareMessage = temporaryMessages[j];
        if (this.isDateRangeEqual(currentMessage.tempDateRange, compareMessage.tempDateRange)) {
          this.isTemporaryMessageOverlap = true;
          return 'Temporary messages cannot overlap in time.';
        }
      }
    }
    this.isTemporaryMessageOverlap = false;
    return null;
  }

  isDateRangeEqual(range1: { startDate: any, endDate: any }, range2: { startDate: any, endDate: any }): boolean {
    const startDate1 = new Date(range1.startDate).getTime();
    const endDate1 = new Date(range1.endDate).getTime();
    const startDate2 = new Date(range2.startDate).getTime();
    const endDate2 = new Date(range2.endDate).getTime();

    // Case 1: Check if start dates or end dates are the same
    if (startDate1 === startDate2 || endDate1 === endDate2) {
      return true;
    }

    // Case 2: Check for Partial Overlap
    const isPartiallyOverlapping =
      (startDate1 < endDate2 && startDate1 >= startDate2) ||
      (startDate2 < endDate1 && startDate2 >= startDate1) ||
      (endDate1 > startDate2 && endDate1 <= endDate2) ||
      (endDate2 > startDate1 && endDate2 <= endDate1);

    // Case 3: Check if the ranges are touching (one ends when the other starts)
    const isTouching = endDate1 === startDate2 || endDate2 === startDate1;

    // Case 4: Check for Adjacent overlap (end date of one equals the start date of another)
    const isAdjacentOverlap = endDate1 === startDate2 || endDate2 === startDate1;

    // Return true if any of the conditions match
    return isPartiallyOverlapping || isTouching || isAdjacentOverlap;
  }
  // Handle date range change
  onDateRangeChange(event: any): void {
    if (event?.startDate && event?.endDate) {
      const formattedStartDate = this.datePipe.transform(event.startDate, 'dd.MM.yyyy');
      const formattedEndDate = this.datePipe.transform(event.endDate, 'dd.MM.yyyy');
      if (formattedStartDate && formattedEndDate) {
        const dateRange = {
          startDate: formattedStartDate,
          endDate: formattedEndDate
        };
        this.message.tempDateRange = dateRange;
        localStorage.setItem('selectedDateRange', JSON.stringify(dateRange));
      }
    }
  }

  // Validate if form is filled correctly
  isFormValid(): boolean {
    for (let message of this.messages) {
      const {
        deutsch, french, english, italian,
        deutschDescription, frenchDescription, englishDescription, italianDescription
      } = message;
    if (
      !deutsch || !french || !english || !italian ||
        !deutschDescription || !frenchDescription || !englishDescription || !italianDescription
    ) {
      return false;
    }

    if (message.display === 'temporary' && (!message.tempDateRange || !message.tempDateRange.startDate || !message.tempDateRange.endDate)) {
      return false;
    }
    }
    return true;
  }

  // Format date range as MM/DD/YYYY - MM/DD/YYYY
  formatDateRange(dateRange: any): string {
    const startDate = new Date(dateRange.startDate);
    const endDate = new Date(dateRange.endDate);
    return `${this.formatDate(startDate)} - ${this.formatDate(endDate)}`;
  }

  // Format a Date object as MM/DD/YYYY
  formatDate(date: Date): string {
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${month}/${day}/${year}`;
  }

  // Convert a date range string to an object { startDate, endDate }
  convertToDateRangeObject(dateRange: string): { startDate: Date, endDate: Date } {
    const [startStr, endStr] = dateRange.split(' - ');
    return {
      startDate: new Date(startStr),
      endDate: new Date(endStr)
    };
  }

  // Check if a new date range overlaps with any existing temporary messages
  isDateRangeOverlapping(newDateRange: { startDate: Date, endDate: Date }): boolean {
    for (const message of this.messages) {
      if (message.display === 'temporary' && message.tempDateRange) {
        const existingDates = this.convertToDateRangeObject(message.tempDateRange);
        if (this.isDateRangeOverlap(newDateRange, existingDates)) {
          return true;
        }
      }
    }
    return false;
  }


  isDateRangeOverlap(range1: { startDate: Date, endDate: Date }, range2: { startDate: Date, endDate: Date }): boolean {
    const startDate1 = range1.startDate.getTime();
    const endDate1 = range1.endDate.getTime();
    const startDate2 = range2.startDate.getTime();
    const endDate2 = range2.endDate.getTime();

    // Case 1: Full overlap (One range fully overlaps the other)
    const isFullyOverlapping = startDate1 < endDate2 && startDate2 < endDate1;

    // Case 2: "Touching" - End of one range is the start of the other
    const isTouching = endDate1 === startDate2 || endDate2 === startDate1;

    // Case 3: Partial overlap - One range partially overlaps the other
    const isPartiallyOverlapping =
      (startDate1 < endDate2 && startDate1 >= startDate2) ||
      (startDate2 < endDate1 && startDate2 >= startDate1);

    // Return true if any of the conditions match
    return isFullyOverlapping || isTouching || isPartiallyOverlapping;
  }

  // Display an error message
  showError(message: string): void {
    this.error = message;
    setTimeout(() => this.dismissError(), 2000);
  }

  // Dismiss error message
  dismissError(): void {
    this.error = '';
  }

  // Display a success message
  showSuccess(message: string): void {
    this.successMessage = message;
    setTimeout(() => this.dismissSuccess(), 2000);
  }

  // Dismiss success message
  dismissSuccess(): void {
    this.successMessage = '';
  }

  // Format current date and time for initialization
  formatCurrentDateTime(): string {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hour = String(now.getHours()).padStart(2, '0');
    const minute = String(now.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hour}:${minute}`;
  }
}
