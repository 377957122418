import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HotelService } from '../../core/services/hotel.service';
import { ManagerService } from '../../core/services/manager.service';
import { Hotel } from '../../core/interfaces/hotel';
import { User } from '../../core/interfaces/user';

@Component({
  selector: 'app-hotel-managers',
  templateUrl: './hotel-managers.component.html',
  styleUrl: './hotel-managers.component.scss'
})
export class HotelManagersComponent {
  breadCrumbItems!: Array<{}>;
  public hotel: any = {};
  searchTerm: string = '';
  public managers:User[] = [];
  public assignedManagers:User[] = [];
  filteredUsers = [...this.managers];
  assignedUsers:User[] = [];
  addManagerImageUrl = '../../../assets/images/add_manger_icon.svg';
  successMessage: string = '';
  error: string = '';

  constructor(
    private hotelService: HotelService,
    private managerService: ManagerService
  ) {}

  ngOnInit(): void {
    this.subscribeToSelectedHotel();
    this.initializeBreadcrumbs();
    this.subscribeToManager();
    this.subscribeToAssignedManager();
  }
  initializeBreadcrumbs(): void {
    this.breadCrumbItems = [
      { label: 'Hotels' },
      { label: 'Hotel managers', active: true }
    ];
  }

  subscribeToSelectedHotel(): void {
    this.hotelService.selectedHotel$.subscribe((hotel: Hotel | null) => {
      if (hotel) {
        this.hotel = hotel;
      }
    });
  }
  subscribeToManager(): void {
    this.managerService.getManagers().subscribe( (manager) => {
      if (manager) {
        this.managers = manager.data.available;
        this.assignedUsers = manager.data.existent;
        this.filteredUsers = [...this.managers];
      }
      console.log(this.managers);
    });
  }
  subscribeToAssignedManager(): void {
    this.managerService.getAssignedManagers().subscribe( (manager) => {
      if (manager) {
        this.assignedUsers = manager.data.data;
      }
      console.log(this.assignedUsers);
    });
  }
  onSearchChange(): void {
    if (this.searchTerm.length >= 3) {
      this.filteredUsers = this.managers.filter((user: User) =>
        (user.name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
         user.id.toString().includes(this.searchTerm)) &&
        !this.assignedUsers.some(u => u.id === user.id) // Exclude assigned users
      );
    } else {
      // When search term is less than 3, just show the available users excluding assigned ones
      this.filteredUsers = this.managers.filter((user: User) =>
        !this.assignedUsers.some(u => u.id === user.id)
      );
    }
  }

  // Method to add a user to assigned users
  addAssignedUser(user: Partial<User>) {
    if (!this.assignedUsers.some(u => u.id === user.id)) {
      this.assignedUsers.push(user as User);
      this.filteredUsers = this.filteredUsers.filter((u: Partial<User>) => u.id !== user.id);
    }
  }

  // Method to remove a user from assigned users
  removeAssignedUser(user: Partial<User>) {
    this.assignedUsers = this.assignedUsers.filter(u => u.id !== user.id);
    this.filteredUsers.push(user as User);
  }

  onSubmit(form: NgForm) {
    const idsList = this.assignedUsers.map(user => user.id);
      const payload = {
        managers: idsList
      }
      this.managerService.saveManager(payload).subscribe(
        (response: any) => {
          this.successMessage = 'Hotel managers saved successfully';
          console.log("Success");
        },
        (error) => {
          this.error = 'There was an error saving the hotel managers list. Please try again.';
          console.error("Failed", error);
        }
      );
  }
  // Display a success message
  showSuccess(message: string): void {
    this.successMessage = message;
    setTimeout(() => this.dismissSuccess(), 2000);
  }

  // Dismiss success message
  dismissSuccess(): void {
    this.successMessage = '';
  }
  // Display an error message
  showError(message: string): void {
    this.error = message;
    setTimeout(() => this.dismissError(), 2000);
  }

  // Dismiss error message
  dismissError(): void {
    this.error = '';
  }
}
