<!-- Page Title -->
<app-pagetitle title="Hotel Gallery" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

<!-- File Upload Section -->
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h4 class="card-title">File upload</h4>
            </div>
            <div class="card-body">
                <div>
                    <dropzone 
                        class="dropzone" 
                        [config]="config" 
                        (dzSuccess)="onFilesUploadSuccess($event)" 
                        (dzError)="onFilesUploadError($event)">
                    </dropzone>
                </div>
                <div class="text-center mt-4">
                    <button type="button" class="btn btn-primary" (click)="sendFiles()">Send Files</button>
                </div>
            </div>
        </div>
    </div>
</div>
  

<!-- Gallery Photos Section -->
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h4 class="card-title">Gallery Photos</h4>
            </div>
            <div class="card-body">
                <!-- Filter Buttons -->
                <div class="text-center">
                    <ul class="list-inline categories-filter button-group" id="filter">
                        <li class="list-inline-item" (click)="activeCategory('all')">
                            <a class="categories" [ngClass]="{'active': galleryFilter === 'all'}" data-filter="*">All</a>
                        </li>
                        <li class="list-inline-item" (click)="activeCategory('general')">
                            <a class="categories" [ngClass]="{'active': galleryFilter === 'general'}" data-filter=".general">General</a>
                        </li>
                        <li class="list-inline-item" (click)="activeCategory('summer')">
                            <a class="categories" [ngClass]="{'active': galleryFilter === 'summer'}" data-filter=".summer">Summer</a>
                        </li>
                        <li class="list-inline-item" (click)="activeCategory('winter')">
                            <a class="categories" [ngClass]="{'active': galleryFilter === 'winter'}" data-filter=".winter">Winter</a>
                        </li>
                    </ul>
                </div>

                <!-- Gallery Images -->
                <div class="row gallery-wrapper" cdkDropList (cdkDropListDropped)="onGalleryDrop($event)" [cdkDropListData]="imageGrid">
                    <div *ngFor="let hotel_image of imageGrid; let i = index" class="element-item col-xl-3 col-sm-6" cdkDrag [cdkDragData]="hotel_image">
                        <div class="align-items-start card seasonal-box-shadow">
                          <i class="material-icons icon-16  mx-auto d-block text-muted px-1 py-2"  aria-hidden="true">view_module</i>
                            <div class="gallery-container">
                                <img class="gallery-img img-fluid mx-auto"
                                    *ngIf="hotel_image.small_image"
                                    [src]="hotel_image.small_image"
                                    [alt]="hotel_image.name"
                                    [ngStyle]="{
                                        'width': hotel_image.small_image === '/assets/images/hotel_placeholder.svg' ? 'auto' : '100%',
                                        'height': hotel_image.small_image === '/assets/images/hotel_placeholder.svg' ? 'auto' : '100%',
                                        'margin': hotel_image.small_image === '/assets/images/hotel_placeholder.svg' ? '10px auto' : '0',
                                        'display': hotel_image.small_image === '/assets/images/hotel_placeholder.svg' ? 'block' : 'inline'
                                    }"
                                    (error)="hotel_image.image_error = true; hotel_image.small_image = '/assets/images/hotel_placeholder.svg';" />
                            </div>
                            <div class="box-content p-2">
                                <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                                    <!-- Loop through the seasons array -->
                                    <div *ngFor="let season of seasons; let j = index">
                                        <input type="checkbox" class="btn-check" [id]="'btnradio' + j + '-' + i" autocomplete="off"
                                            [checked]="hotel_image.season_image === season"
                                            (change)="onSeasonabilityChange(i, hotel_image.id, season)">
                                        <label class="btn btn-outline-secondary seasonal-check" [for]="'btnradio' + j + '-' + i">{{ season | titlecase }}</label>
                                    </div>
                                </div>
                            </div>                                                       
                            <div class="box-content mb-2">
                                <div class="form-check form-switch form-switch-md mx-3">
                                    <input class="form-check-input cursor-pointer" type="checkbox" id="flexSwitchCheckDefault{{i}}"
                                        [checked]="hotel_image.is_main == 1"
                                        (change)="onMainImageToggle($event, i);hotel_image.is_main = $event.target">
                                    <label class="form-check-label season-tab-size cursor-pointer" for="flexSwitchCheckDefault{{i}}">Set as main image</label>
                                </div>
                                <button type="button" class="btn text-muted text-uppercase season-tab-size mx-1 fw-bold"
                                    data-bs-toggle="modal"
                                    data-bs-target="#staticBackdrop"
                                    (click)="openDeleteModal(deleteImageModal, i)">Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Delete Image Modal -->
<ng-template #deleteImageModal let-modal>
    <div class="modal-header delete-modal-header">
        <h5 class="modal-title mt-0">Delete Image</h5>
        <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')" aria-hidden="true"></button>
    </div>
    <div class="modal-body">
        <p>Are you sure you want to delete?</p>
    </div>
    <div class="modal-footer delete-modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
        <button type="button" class="btn btn-primary submit_btn" (click)="confirmDelete(modal)">Okay</button>
    </div>
</ng-template>
