import { Component, OnInit } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { UspService } from '../../core/services/usp.service';
import { LanguageService } from '../../core/services/language.service';

interface USP {
  name: string;
  de: string;
  en: string;
  fr: string;
  it: string;
  [key: string]: string; // Allow dynamic access, e.g. usp['de']
}

@Component({
  selector: 'app-usp',
  templateUrl: './usp.component.html',
  styleUrl: './usp.component.scss'
})

export class UspComponent implements OnInit{
  breadCrumbItems!: Array<{label: string; link?: string; active?: boolean}>;
  public hotel: any = {};
  successMessage = '';
  isError: string | boolean | null = null;
  error: string = '';
  messages: any[] = [];
  usps: USP[] = [];
  languages:  Array<{ id: number; code: string; name: string; is_default: number; is_enabled: number }>  = [];
  newArr: any = {};

  constructor(
    private uspservice: UspService,
    private languageService: LanguageService,
  ) {

  }

  ngOnInit():void{
    this.initializeBreadcrumbs();
    this.subscribToUsp();
    this.getlanguages();
  }
  initializeBreadcrumbs(): void {
    this.breadCrumbItems = [
      { label: 'USPs',link: '/usp' },
      { label: 'USPs Overview',link: '/usp', active: true }
    ];
  }

  getlanguages(): void {
    this.languageService.getlanguages().subscribe( result  => {
      if (result) {
        this.languages = result.data;
      }
    });
  }

  subscribToUsp(): void{
    this.uspservice.getUspDetails().subscribe((response: any) => {
      if (response && Array.isArray(response.data)) {
        this.usps = response.data;
      } else {
        this.usps = [];
      }
      this.updateUspNames();
    }, error => {
      console.error('Error fetching data', error);
    });
  }

  addUsp() {
    if (this.usps.length < 4) {
      let newUsp: USP = { name: '', de: '', en: '', fr: '', it: '' };
      this.usps.push(newUsp);
      this.updateUspNames();
    } else {
    }
  }

  // Update USP names based on their index.
  updateUspNames() {
    this.usps.forEach((usp, index) => {
      usp.name = `usp_${index + 1}`;
    });
  }

  saveChanges(form: any) {
    this.updateUspNames();
    const formattedData = {
      usps: this.usps.map((usp: USP) => ({
        name: usp['name'],
        usp: {
          de: usp['de'],
          en: usp['en'],
          fr: usp['fr'],
          it: usp['it']
        }
      }))
    };

    if (form.valid) {
      this.uspservice.saveUspDetails(formattedData).subscribe(
        (response: any) => {
          this.usps = response.data;
          this.successMessage = response.message;
          console.log("Success");
        },
        (error) => {
          this.error = error.message;
          console.error("Failed", error);
        }
      );
    } else {
      this.error = 'Please fill in all required fields.';
      this.isError = true;
    }
  }

  onDrop(event: CdkDragDrop<any[]>) {
    const previousIndex = this.usps.findIndex(service => service === event.item.data);
    const currentIndex = event.currentIndex;
    this.moveItemInArray(this.usps, previousIndex, currentIndex);
  }

  // Utility function to reorder array
  moveItemInArray(array: any[], fromIndex: number, toIndex: number) {
    const [removed] = array.splice(fromIndex, 1);
    array.splice(toIndex, 0, removed);
  }

  deleteUsp(index: number): void {
    const findUsp = this.usps[index];
    const payload = {
      usp_names: this.usps[index]
    }
    this.uspservice.deleteUspData(payload).subscribe(
      (response: any) => {
        this.successMessage = response.message;
      },
      (error) => {
        this.error = error.message;
      }
    );
  }

  showSuccess(message: string): void {
    this.successMessage = message;
    setTimeout(() => this.dismissSuccess(), 2000);
  }

  dismissSuccess(): void {
    this.successMessage = '';
  }

  showError(message: string): void {
    this.error = message;
    setTimeout(() => this.dismissError(), 2000);
  }

  dismissError(): void {
    this.error = '';
  }
}
