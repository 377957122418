<div *ngIf="error" class="alert alert-danger alert-dismissible fade show mt-3">
  <i class="uil uil-exclamation-triangle me-2"></i>
  <span class="ms-2">{{ error }}</span>
  <button type="button" class="btn-close mt-1" aria-label="Close" (click)="dismissError()"></button>
</div>

<div *ngIf="successMessage" class="alert alert-success alert-dismissible fade show mt-3">
  <i class="uil uil-check me-2"></i>
  <span class="ms-2">{{ successMessage }}</span>
  <button type="button" class="btn-close mt-1" aria-label="Close" (click)="dismissSuccess()"></button>
</div>

<div class="container-fluid mt-3">
  <div class="d-flex justify-content-between align-items-center">
    <app-pagetitle title="Unique Selling Proposition"></app-pagetitle>
  </div>

  <button type="button"
      class="btn btn-primary submit_btn position-absolute add-room-btn cursor-pointer add-service-btn"
      (click)="addUsp()"
      [ngClass]="{ 'disabled-service-btn': usps.length >= 4 }"
      [disabled]="usps.length >= 4">
      Add USP &nbsp;
      <i class="fas fa-plus fa-sm"></i>
  </button>

  <p class="booking-desc-msg mt-3">
    Describe concisely up to four unique hotel properties.
  </p>

  <div cdkDropList class="services-container" (cdkDropListDropped)="onDrop($event)">
    <div class="card mt-5" cdkDrag>
      <div class="card-body">
        <form (ngSubmit)="saveChanges(uspForm)" #uspForm="ngForm">
          <div class="row mb-3 position-relative usp-main" *ngFor="let usp of usps; let i = index">
            <!-- USP index label -->
            <label for="usp{{ i }}" class="form-label col-1">{{ 'USP ' + (i + 1) }}</label>
            <div class="col-10">
              <div class="row">
                <!-- Language Fields -->
                <div class="col-md-6" *ngFor="let lang of languages">
                  <div class="mb-3 position-relative">
                    <div class="d-flex mb-2">
                      <label class="px-3 py-2 text-room-color form-input-bg h-40 w-120 lang-field"
                          [ngClass]="{'border-red': !usp[lang.code] && isError}">
                        {{ lang.code | uppercase }}
                      </label>
                      <div class="textarea-container">
                        <textarea
                          [(ngModel)]="usp[lang.code]"
                          [name]="'usps[' + i + '].' + lang.code"
                          class="form-control usp form-input-bg text-room-desc h-40"
                          required maxlength="40"
                          [ngClass]="{'border-red': !usp[lang.code] && isError}">
                        </textarea>
                      </div>
                      <div class="count-label">
                        {{ (usp[lang.code]?.length || 0) }}/40
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Delete and Drag Icons -->
            <div class="col-1 d-flex align-items-start justify-content-end mt-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                   viewBox="0 0 24 24" fill="none" (click)="deleteUsp(i)"
                   class="cursor-pointer">
                <g id="trash-outline 1">
                  <path id="Vector" d="M5.25 5.25L6.1875 20.25C6.23203 21.1167 6.8625 21.75 7.6875 21.75H16.3125C17.1408 21.75 17.7595 21.1167 17.8125 20.25L18.75 5.25" stroke="#F74D4D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <g id="Vector_2">
                    <path d="M3.75 5.25H20.25H3.75Z" fill="#F74D4D"/>
                    <path d="M3.75 5.25H20.25" stroke="#F74D4D" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
                  </g>
                  <path id="Vector_3" d="M9 5.25V3.375C8.99957 3.22715 9.02837 3.08066 9.08475 2.94397C9.14114 2.80729 9.22399 2.6831 9.32854 2.57854C9.43309 2.47399 9.55728 2.39114 9.69397 2.33476C9.83066 2.27838 9.97714 2.24957 10.125 2.25H13.875C14.0229 2.24957 14.1693 2.27838 14.306 2.33476C14.4427 2.39114 14.5669 2.47399 14.6715 2.57854C14.776 2.6831 14.8589 2.80729 14.9152 2.94397C14.9716 3.08066 15.0004 3.22715 15 3.375V5.25M12 8.25001V18.75M8.625 8.25001L9 18.75M15.375 8.25001L15 18.75" stroke="#F74D4D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
              </svg>
              <img src="assets/images/drag.png" alt="drag-icon" class="img-fluid ms-3 cursor-pointer">
            </div>
          </div>
          <div class="text-end mt-3" *ngIf="usps.length > 0">
            <button type="submit" class="btn btn-primary submit_btn cursor-pointer">
              Save changes
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<ng-template #uspLimitModal let-modal>
  <div class="modal-header border-bottom-none">
    <svg xmlns="http://www.w3.org/2000/svg" width="53" height="52" viewBox="0 0 53 52" fill="none" class="warning-circle-main">
      <circle cx="26.5" cy="26" r="26" fill="#FBB041" fill-opacity="0.1"/>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" class="warning-circle-inner">
      <path d="M10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20ZM10 5C10.5195 5 10.9375 5.41797 10.9375 5.9375V10.3125C10.9375 10.832 10.5195 11.25 10 11.25C9.48047 11.25 9.0625 10.832 9.0625 10.3125V5.9375C9.0625 5.41797 9.48047 5 10 5ZM8.75 13.75C8.75 13.4185 8.8817 13.1005 9.11612 12.8661C9.35054 12.6317 9.66848 12.5 10 12.5C10.3315 12.5 10.6495 12.6317 10.8839 12.8661C11.1183 13.1005 11.25 13.4185 11.25 13.75C11.25 14.0815 11.1183 14.3995 10.8839 14.6339C10.6495 14.8683 10.3315 15 10 15C9.66848 15 9.35054 14.8683 9.11612 14.6339C8.8817 14.3995 8.75 14.0815 8.75 13.75Z" fill="#FBB041"/>
    </svg>
  </div>
  <div class="modal-body">
    <p class="check-text-color">You can add only up to 4 USP at once..</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary submit_btn w-100" (click)="modal.close()">OK</button>
  </div>
</ng-template>
