import { Component } from '@angular/core';
import { HotelService } from '../../core/services/hotel.service';
import { LanguageService } from '../../core/services/language.service';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-hotel-description',
  templateUrl: './hotel-description.component.html',
  styleUrl: './hotel-description.component.scss',
})
export class HotelDescriptionComponent {
  breadCrumbItems!: Array<{}>;
  public hotel: any = {};
  success:boolean = false;
  error: string = '';
  description: any = {};
  secondary_description: any = {};
  languages = [
    { key: 1, code: 'de', name: 'Deutsch' },
    { key: 2, code: 'en', name: 'English' },
    { key: 3, code: 'fr', name: 'Français' },
    { key: 4, code: 'it', name: 'Italiano' }
  ];
  selectedLanguage = this.languages[0].code; // Default to the first language

  constructor(
    private hotelService: HotelService,
    public translate: TranslateService,
    public cookieService: CookieService,
    private languageService: LanguageService
  ) {
    const lang = this.cookieService.get('selectedLanguage') || 'en';
    this.languageService.setLanguage(lang); // Set the initial language
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
  }
  ngOnInit(): void {
    this.subscribeToHotelDescription();
    this.initializeBreadcrumbs();
    this.getlanguages();
  }
  subscribeToHotelDescription(): void {
    this.hotelService.getDescription().subscribe((result) => {
      console.log(result);
      if (result) {
        this.description = result.data.description;
        this.secondary_description = result.data.secondary_description;
      }
    });
  }
  initializeBreadcrumbs(): void {
    this.breadCrumbItems = [
      { label: 'Hotels' },
      { label: 'Hotel description', active: true }
    ];
  }
  getlanguages() {
    this.languageService.getlanguages().subscribe( result  => {
      if (result) {
        this.languages = result.data;
      }
    });
  }

  onSubmit(form: NgForm) {
    if (form.invalid) {
      this.error = 'Please fill in all required fields.';
      return;
    }
    if (form.valid) {
      this.error = '';
      this.success = true;
      const description:any = {};
      const secondary_description:any = {};
      window.scrollTo({ top: 0, behavior: 'smooth' });

      this.languages.forEach((language:any) => {
        description[language.code] = form.value[`description_${language.code}`];
        secondary_description[language.code] = form.value[`secondary_description_${language.code}`];
      });
      const payload = {
        description: description,
        secondary_description: secondary_description,
      };
      this.hotelService.saveDescription(payload).subscribe(
        (response: any) => {
          this.success = true;
          console.log("Success");
        },
        (error) => {
          this.success = false;
          console.error("Failed", error);
        }
      );
      setTimeout(() => {
        this.success = false;
      }, 3000);
    } else {
      console.log('Form is invalid');
    }
  }

  selectLanguage(languageCode: string) {
    this.selectedLanguage = languageCode;
  }
}
