
<div *ngIf="error" class="alert alert-danger alert-dismissible fade show mt-3">
  <i class="uil uil-exclamation-triangle me-2"></i>
  <span class="ms-2">
    {{ error }}
  </span>
  <button type="button" class="btn-close" aria-label="Close" (click)="error = ''"></button>
</div>
<div *ngIf="success" class="alert alert-success alert-dismissible contact-alert fade show mt-3 d-flex">
  <i class="uil uil-check me-2"></i>
  <span class="ms-2 contact-desc-alert">
    Contact Saved successfully
  </span>
  <button type="button" class="btn-close" aria-label="Close" (click)="success = false"></button>
</div>
<!-- start page title -->
<app-pagetitle title="Contact" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->
<form #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm)">
  <div class="row mt-3">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <div class="card card-h-100">
              <div class="card-body">
                  <div class="">
                          <div class="row">
                            <div class="col-md-12">
                              <label class="contact-hotel-label">Contact person for HotelCard</label>
                            </div>
                            <div class="col-md-12">
                              <div class="mb-3">
                                <label class="form-label">Salutation</label><br>
                                <div class="form-check form-check-inline" *ngFor="let option of radioOptions">
                                  <input
                                    class="form-check-input form-input-radio form-input-bg"
                                    type="radio"
                                    name="gender"
                                    [id]="'formRadios-' + option.id"
                                    [(ngModel)]="hotel_contact.gender"
                                    [value]="option.gender"
                                    required>
                                  <label class="form-check-label" [for]="'formRadios-' + option.id">
                                    {{ option.label | translate }}  <!-- This should work for all languages -->
                                  </label>
                                </div>

                              <div *ngIf="contactForm.submitted && !hotel_contact.gender" class="text-danger">
                                  Gender is required.
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12" hidden>
                              <label class="form-label" for="name">Hotel Name</label>
                          <input type="text" class="form-control form-input-bg" id="name" name="name" [(ngModel)]="hotel.name" hidden>
                            </div>
                            <div class="col-md-12">
                              <div class="mb-3">
                                <label class="form-label" for="first_name">First name</label>
                                <input type="text" class="form-control form-input-bg" id="first_name" name="first_name" [(ngModel)]="hotel_contact.first_name" #first_name="ngModel" required>
                                <div *ngIf="first_name.invalid && (first_name.dirty || first_name.touched)" class="text-danger">
                                  <div *ngIf="first_name.errors?.['required']">First name is required.</div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="mb-3">
                                <label class="form-label" for="last_name">Last name</label>
                                <input type="text" class="form-control form-input-bg" id="last_name" name="last_name" [(ngModel)]="hotel_contact.last_name" #last_name="ngModel" required>
                                <div *ngIf="last_name.invalid && (last_name.dirty || last_name.touched)" class="text-danger">
                                  <div *ngIf="last_name.errors?.['required']">Last name is required.</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-12">
                              <div class="mb-3">
                                <label class="form-label" for="role">Role</label>
                                <input type="text" class="form-control form-input-bg" id="role" name="role" [(ngModel)]="hotel_contact.role" #role="ngModel" required>
                                <div *ngIf="role.invalid && (role.dirty || role.touched)" class="text-danger">
                                  <div *ngIf="role.errors?.['required']">Role is required.</div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="mb-3">
                                <label class="form-label" for="email">E-mail</label>
                                <input type="email" class="form-control form-input-bg" id="email" name="email" [(ngModel)]="hotel_contact.email" #email="ngModel" required>
                                <div *ngIf="email.invalid && (email.dirty || email.touched)" class="text-danger">
                                  <div *ngIf="email.errors?.['required']">E-mail is required.</div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="mb-3">
                                <label class="form-label" for="phone_number">Phone</label>
                                <input type="text" class="form-control form-input-bg" id="phone_number" name="phone_number" placeholder="(incl. international area code)" [(ngModel)]="hotel_contact.phone_number" #phone_number="ngModel" required>
                                <div *ngIf="contactForm.submitted && !hotel_contact.phone_number" class="text-danger">
                                  Phone is required.
                                </div>
                              </div>
                            </div>
                            <p class="contact-desc-msg mt-3 ms-2">{{ 'CONTACTINFO.DETAILS' | translate }}</p>
                          </div>
                  </div>
              </div><!-- end card body -->
          </div><!-- end card -->
      </div><!-- end col -->
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <div class="card card-h-100">
          <div class="card-body">
            <div class="">
              <div class="row">
                <div class="col-md-12">
                  <label class="contact-hotel-label">Contact for customers</label>
                </div>
                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="form-label" for="phone_hotel">Hotel Phone</label>
                    <input type="text" class="form-control form-input-bg" id="phone_hotel" name="number" placeholder="(incl. international area code)" [(ngModel)]="hotel.number" #number="ngModel" required>
                    <div *ngIf="contactForm.submitted && !hotel.number" class="text-danger">
                      Hotel Phone is required.
                    </div>
                  </div>
                </div>
               <div class="col md-12 no-bookings-req">
                <div class="form-check form-switch form-switch-md mb-2 text-room-desc position-absolute hide-room-view contact-view" dir="ltr">
                  <input
                    type="checkbox"
                    class="form-check-input position-absolute form-hide-view-switch contact-hide-switch cursor-pointer"
                    [id]="'phone_requests' + hotel.id"
                    name="phone_requests"
                    [(ngModel)]="hotel.phone_requests"
                    [checked]="isPhoneRequestsHidden(hotel)"
                    (change)="onHideChange(hotel, $event)"/>
                    <label class="form-check-label mt-3 cursor-pointer label-switch-text contact-label-switch position-relative fw-normal" [for]="'phone_requests'+ hotel.id">
                      {{ 'CONTACTINFO.NO_BOOKING_REQUESTS' | translate }}
                    </label>
                </div>
               </div>
               <hr class="horiz-line">
                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="form-label" for="general_email">E-mail for general questions</label>
                    <input type="email" class="form-control form-input-bg" id="general_email" name="general_email" [(ngModel)]="hotel.general_email" #general_email="ngModel" required>
                    <div *ngIf="general_email.invalid && (general_email.dirty || general_email.touched)" class="text-danger">
                      <div *ngIf="general_email.errors?.['required']">Email for general questions is required.</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="form-label" for="booking_email">E-mail for hotel bookings</label>
                    <input type="email" class="form-control form-input-bg" id="booking_email" name="booking_email" [(ngModel)]="hotel.booking_email" #booking_email="ngModel" required>
                    <div *ngIf="booking_email.invalid && (booking_email.dirty || booking_email.touched)" class="text-danger">
                      <div *ngIf="booking_email.errors?.['required']">E-mail for hotel bookings is required.</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="form-label" for="booking_language_id">Language for booking requests</label>
                    <select class="form-select form-input-bg" id="booking_language_id" name="booking_language_id" [(ngModel)]="hotel.booking_language_id">
                        <option value="">Select</option>
                        <option *ngFor="let language of languages" [value]="language.id">{{ language.name }}</option>
                    </select>
                    <div *ngIf="contactForm.invalid && contactForm.controls['booking_language_id']?.touched" class="text-danger">
                      Language for booking requests is required.
                    </div>
                  </div>
                </div>
                <p class="contact-desc-msg mt-3 ms-2">{{ 'CONTACTINFO.STATUS' | translate }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-1">
        <button type="submit" class="btn btn-primary float-md-end submit_btn w-md">Save Contact</button>
      </div>
  </div><!-- end row -->
</form>

