import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';
import { ArticleService } from '../../core/services/article.service';
import { LanguageService } from '../../core/services/language.service';
import moment from 'moment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Article, Period } from '../../core/interfaces/article';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent {
  breadCrumbItems!: Array<{label: string; link?: string; active?: boolean}>;
  cards: Array<any> = [];
  public hotel: any = {};
  selectAll: boolean = false;
  calendarIconSrc = 'assets/images/calendar.png';
  timePeriods: any[] = [];
  languages: any;
  roomCategories: any;
  services: any[] = [];
  successMessage: string = '';
  error: string = '';
  selectedDateRange: any = {};
  isTemporary: string = '';
  serviceForm!: FormGroup;
  currency: string = '';

  priceOptions = [
    { name: 'per guest_night' },
    { name: 'per guest_stay' },
    { name: 'per night' },
    { name: 'per stay' }
  ];
  service_days = [
    { name: 'Monday', is_active: true },
    { name: 'Tuesday', is_active: true },
    { name: 'Wednesday', is_active: true },
    { name: 'Thursday', is_active: true },
    { name: 'Friday', is_active: true },
    { name: 'Saturday', is_active: false },
    { name: 'Sunday', is_active: false },
  ];

  // Property to track the checkbox state
  constructor(
    private articleService: ArticleService,
    private languageService: LanguageService,
    private fb: FormBuilder,
  ) { }

  ngOnInit():void{
    this.getArticles();
    this.initializeBreadcrumbs();
    this.getlanguages();
    this.getRooms();
    this.serviceForm = this.fb.group({
      services: this.fb.array([])
    });
  }

  // Initialize breadcrumbs for navigation
  initializeBreadcrumbs(): void {
    this.breadCrumbItems = [
      { label: 'Services',link: '/services' },
      { label: 'Service Overview',link: '/services', active: true }
    ];
  }

  getArticles() {
    this.articleService.getArticles().subscribe(result => {
      this.services = result.data;
      for (let i = 0; i < this.services.length; i++) {
        this.currency = this.services[i].time_periods[0].currency
        this.timePeriods[i] = this.services[i].time_periods[0];
        const startDate = this.timePeriods[i].daterange?.start_date || moment().format('YYYY-MM-DD');
        const endDate = this.timePeriods[i].daterange?.end_date || moment().format('YYYY-MM-DD');
        this.timePeriods[i].daterange = {
          startDate: moment(startDate, 'YYYY-MM-DD'),
          endDate: moment(endDate, 'YYYY-MM-DD')
        };
        this.services[i].service_days = JSON.parse(JSON.stringify(this.service_days));
        this.services[i].service_days.forEach((day: any) => {
          day.is_active = this.services[i].time_periods[0].deal_days[day.name.toLowerCase()] === 1;
        });
        this.services[i].selectAll = this.services[i].service_days.every((day: any) => day.is_active);
      }
    });
  }

  getRooms()
  {
    this.articleService.getHotelRooms().subscribe(response => {
      this.roomCategories = response.data.data;
    });
  }

  getlanguages() {
    this.languageService.getlanguages().subscribe( result  => {
      if (result) {
        this.languages = result.data;
      }
    });
  }

  // Method to add a new service (i.e., a new card)
  addService(): void {
    let newService: any = {
      id: null,
      name: {},
      time_periods: [
        {
          id: null,
          price: '',
          currency: this.currency, // default hotel currency
          is_mandatory_service: 0,
          is_permanent_service: 1,
          daterange: {
            start_date: moment().format('YYYY-MM-DD'),
            end_date: moment().format('YYYY-MM-DD')
          },
          price_option: 'per_night',
          rooms: {}
        }
      ],
      service_days: JSON.parse(JSON.stringify(this.service_days)),
      selectAll: false
    };
    if (this.languages && this.languages.length > 0) {
      this.languages.forEach((lang: any) => {
        newService.name[lang.code] = '';
      });
    }
    // For each available room, set the default value to 0 (unchecked).
    if (this.roomCategories && this.roomCategories.length > 0) {
      this.roomCategories.forEach((room: any) => {
        newService.time_periods[0].rooms[room.id] = 0;
      });
    }
    this.services.push(newService);
  }
  //Prepares the payload for submission in the required JSON format.
  preparePayload(): { articles: Article[] } {
    const articles: Article[] = this.services.map((service) => {
      const dealDays = service.service_days.reduce((acc: any, day: any) => {
        acc[day.name.toLowerCase()] = day.is_active ? 1 : 0;
        return acc;
      }, {});

      const periods: Period[] = service.time_periods.map((period: any) => ({
        id: period.id,
        start_date: period.start_date,
        end_date: period.end_date,
        currency: period.currency,
        price: period.price,
        price_option: period.price_option,
        deal_days: dealDays,
        allow_all_rooms: period.allow_all_rooms !== undefined ? period.allow_all_rooms : 0,
        rooms: period.rooms,
        is_mandatory_service: period.is_mandatory_service ? 1 : 0
      }));

      return {
        id: service.id,
        name: service.name,
        sorting: service.sorting,
        is_permanent_service: service.time_periods[0].is_permanent_service ? 1 : 0,
        periods: periods
      };
    });

    return { articles };
  }

  submitServices(): void {
    const payload = this.preparePayload();
    this.articleService.saveArticles(payload).subscribe(
      (response: any) => {
        console.log(response);
        this.successMessage = response.message;
        console.log("Success");
      },
      (error) => {
        this.successMessage = '';
        console.error("Failed", error);
      }
    );
  }

  // Function to handle changes on the 'permanent' radio button
  checkPermanentMessage(type: string, id: number) {
    console.log(`Radio button selected: ${type} for service index ${id}`);
    if (type == 'temporary') {
      this.isTemporary = 'temporary';
    } else {
      this.isTemporary = 'permanent';
    }
  }

  onDrop(event: CdkDragDrop<any[]>): void {
    moveItemInArray(this.services, event.previousIndex, event.currentIndex);
    this.services.forEach((service, index) => {
      service.sorting = index + 1;
    });
  }

  toggleSelectAllRooms(serviceIndex: number, event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    const service = this.services[serviceIndex];
    if (!service || !service.time_periods?.length) {
      return;
    }
    const currentRooms = service.time_periods[0].rooms;
    const updatedRooms = { ...currentRooms };
    this.roomCategories.forEach((room: any) => {
      if (updatedRooms.hasOwnProperty(room.id)) {
        updatedRooms[room.id] = isChecked ? 1 : 0;
      }
    });
    service.time_periods[0].rooms = updatedRooms;
    this.services = [...this.services];
  }

  toggleApplicableToAllRooms(serviceIndex: number, roomIndex: number, event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    const room = this.roomCategories[roomIndex];
    this.services[serviceIndex].time_periods[0].rooms[room.id] = isChecked ? 1 : 0;
    this.services = [...this.services];
  }

  areAllRoomsSelected(rooms: any): boolean {
    return Object.keys(rooms)
      .filter(key => !isNaN(Number(key))) // Only consider numeric keys (room IDs)
      .every(key => rooms[key] === 1);
  }

  focusInput(dateInput: HTMLInputElement): void {
    dateInput.focus();
  }

  toggleActivityStatus(dealDay: any, serviceIndex: number): void {
    const service = this.services[serviceIndex];
    if (!dealDay.is_active) {
      service.selectAll = false;
    } else if (dealDay.is_active){
      service.selectAll = true;
    } else {
      service.selectAll = this.service_days.every((day: any) => day.is_active);
    }
  }

  // Toggle "All days" for a specific service.
  toggleSelectAll(serviceIndex: number): void {
    const service = this.services[serviceIndex];
    const newStatus = !service.selectAll;
    service.service_days.forEach((day: any) => day.is_active = newStatus);
    service.selectAll = newStatus;
  }

  onDateRangeChange(event: any, serviceIndex: number): void {
    const period = this.services[serviceIndex].time_periods[0];
    if (event) {
      // If the picker returns Moment objects, format them; otherwise, use the raw values.
      period.start_date = event.startDate && event.startDate.format
        ? event.startDate.format('YYYY-MM-DD')
        : event.startDate;
      period.end_date = event.endDate && event.endDate.format
        ? event.endDate.format('YYYY-MM-DD')
        : event.endDate;
    } else {
      period.start_date = null;
      period.end_date = null;
    }
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  deleteService(index: number): void {
    const service = this.services[index];
    if (service && service.id) {
      const payload = {
        article_ids: [service.id.toString()]
      };
      this.articleService.deleteArticles(payload).subscribe({
        next: () => {
          this.services.splice(index, 1);
          this.successMessage = 'Service deleted successfully!';
        },
        error: (error) => {
          this.error = 'An error occurred while deleting the service.';
          console.error(error);
        }
      });
    } else {
      this.services.splice(index, 1);
      this.successMessage = 'Service removed locally.';
    }
  }

  showSuccess(message: string): void {
    this.successMessage = message;
    setTimeout(() => this.dismissSuccess(), 2000);
  }

  dismissSuccess(): void {
    this.successMessage = '';
  }

  showError(message: string): void {
    this.error = message;
    setTimeout(() => this.dismissError(), 2000);
  }

  dismissError(): void {
    this.error = '';
  }
}
