import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { CookiesService } from './cookies.service';
import { Hotel } from '../interfaces/hotel';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {
  private selectedHotelKey = 'selectedHotel';
  private selectedHotelSubject = new BehaviorSubject<Hotel | null>(null);
  selectedHotel$ = this.selectedHotelSubject.asObservable();

  constructor(
    private http: HttpService,
    private cookieService: CookiesService
  ) { }

  getSelectedHotel(): string | null {
    return this.cookieService.get(this.selectedHotelKey);
  }

  getArticles(){
    const selectedHotelId = this.getSelectedHotel();
    return this.http.get(`v2/hotels/articles/${selectedHotelId}`);
  }

  saveArticles(data: any) {
    const selectedHotelId = this.getSelectedHotel();
    return this.http.post(`v2/hotels/articles/${selectedHotelId}`, data);
  }

  deleteArticles(data: any) {
    const selectedHotelId = this.getSelectedHotel();
    return this.http.delete(`v2/hotels/articles/${selectedHotelId}`, data);
  }

  getRoomDetails(roomId: number) {
    const selectedHotelId = this.getSelectedHotel();
    return this.http.get(`v2/${selectedHotelId}/rooms/${roomId}`);
  }

  getHotelRooms() {
    const selectedHotelId = this.getSelectedHotel();
    return this.http.get(`v2/${selectedHotelId}/rooms`);
  }
}
