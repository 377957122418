import { Component, OnInit } from '@angular/core';
import { Hotel } from '../../core/interfaces/hotel';
import { HotelService } from '../../core/services/hotel.service';
import { CountryService } from '../../core/services/country.service';
import { Country } from '../../core/interfaces/country';
import { NgForm } from '@angular/forms';
import { FormArray, FormBuilder, FormGroup,  Validators } from '@angular/forms';
import moment from 'moment';

@Component({
  selector: 'app-hotel-information',
  templateUrl: './hotel-information.component.html',
  styleUrls: ['./hotel-information.component.scss']
})
export class HotelInformationComponent implements OnInit {
  breadCrumbItems!: Array<{}>;
  public hotel: any = { discount_rules: [] };
  public ratingOptions: any = {};
  public country: any = {};
  public hotelType: any = {};
  countries:Country[]=[];
  regions:Country[]=[];
  successMessage = '';
  error = '';
  calendarIconSrc = 'assets/images/calendar.png';
  dateRangeForm: FormGroup;
  plusCode: string = '';
  center: google.maps.LatLngLiteral = { lat: 37.7749, lng: -122.4194 }; // Default to San Francisco
  zoom = 15;
  markerPosition: google.maps.LatLngLiteral | null = null;
  showPlusCodeSection:boolean =false;

  currencyOptions = [
    { id: 'currencyCHF', value: 'CHF', label: 'CHF' },
    { id: 'currencyEUR', value: 'EUR', label: 'EUR' },
  ];


  constructor(private hotelService: HotelService,
    private countryService: CountryService,
    private fb: FormBuilder,
  )
  {
    this.ratingOptions = [
      {value: 1, name: 1},
      {value: 2, name: 2},
      {value: 3, name: 3},
      {value: 4, name: 4},
      {value: 5, name: 5},
      {value: 'no_rating', name: 'No Rating'},
      {value: 6, name: 'Swiss Lodge'}
    ];
    this.hotelType = [
      {id:'full', value:'Full year'},
      {id:'seasonal', value:'Seasonal'}
    ];
    this.dateRangeForm = this.fb.group({
      periods: this.fb.array([
        this.fb.group({
          dateRange: ['', Validators.required]
        })
      ])
    });
  }

  ngOnInit(): void {
    // Initialize component
    this.subscribeToSelectedHotel();
    this.loadCountries();
    this.initializeBreadcrumbs();
    this.checkOverlap();

    const periodsArray = this.dateRangeForm.get('periods') as FormArray;
    periodsArray.controls.forEach(control => {
      control.get('dateRange')?.valueChanges.subscribe(() => {
        control.get('dateRange')?.updateValueAndValidity();
      });
    });
  }

  loadCountries(): void {
    this.countryService.getAllCountries().subscribe(
      (data: Country[]) => {
        this.countries = data;
        this.fetchRegionsByHotelCountry();
      },
      (error) => {
        console.error('Error fetching countries:', error);
      }
    );
  }

  focusInput(dateInput: HTMLInputElement): void {
    dateInput.focus();
  }

  initializeBreadcrumbs(): void {
    this.breadCrumbItems = [
      { label: 'Hotels' },
      { label: 'Hotel information', active: true }
    ];
  }
  get periods(): FormArray {
    return this.dateRangeForm.get('periods') as FormArray;
  }

  subscribeToSelectedHotel(): void {
    this.hotelService.getHotelInfo().subscribe(result => {
      if (result) {
        this.hotel = result.data;
        this.periods.clear(); // Clear existing periods before adding new ones

        const sortedRules = this.hotel.discount_rules.sort((a: any, b: any) => {
          const dateA = moment(a.start_date, 'YYYY-MM-DD');
          const dateB = moment(b.start_date, 'YYYY-MM-DD');
          return dateA.diff(dateB);
        });

        sortedRules.forEach((rule: any) => {
          const dateRangeValue = rule.start_date && rule.end_date
            ? {
                startDate: moment(rule.start_date, 'YYYY-MM-DD'),
                endDate: moment(rule.end_date, 'YYYY-MM-DD')
              }
            : null;

          this.periods.push(
            this.fb.group({
              dateRange: [dateRangeValue, Validators.required]
            })
          );
        });
      }
    });
  }
  ngAfterViewInit() {
    // Iterate over each control and convert string values to object format.
    this.periods.controls.forEach(control => {
      const currentValue = control.get('dateRange')?.value;
      if (typeof currentValue === 'string') {
        const parts = currentValue.split(' - ');
        if (parts.length === 2) {
          const start = moment(parts[0], 'YYYY-MM-DD');
          const end = moment(parts[1], 'YYYY-MM-DD');
          // Update the control value to be an object with Moment instances.
          control.get('dateRange')?.setValue({ start, end }, { emitEvent: false });
        }
      }
    });
  }

  setDefaultCountry(): void {
    const defaultCountryIndex = this.getDefaultCountryIndex();
    if (defaultCountryIndex !== undefined) {
      this.fetchRegionsByCountry(defaultCountryIndex);
    }
  }

  setInitialCountryAndRegions(): void {
    const defaultCountryIndex = this.getDefaultCountryIndex();
    if (defaultCountryIndex !== undefined) {
      this.fetchRegionsByCountry(defaultCountryIndex);
    }
  }

  fetchRegionsByHotelCountry(): void {
    if (this.hotel.country_id) {
      const countryIndex = this.countries.findIndex(country => country.id === this.hotel.country_id);
      if (countryIndex !== -1) {
        this.fetchRegionsByCountry(countryIndex);
      }
    }
  }

  fetchRegionsByCountry(countryIndex: number): void {
    const selectedCountry = this.countries[countryIndex];
    this.countryService.getRegions(selectedCountry.id).subscribe(
      (regions: any[]) => {
        this.regions = regions;
      },
      (error) => {
        console.error(`Error fetching regions for country ${selectedCountry.name}:`, error);
      }
    );
  }

  onSubmit(form: NgForm) {
    this.dateRangeForm.markAllAsTouched();
    if (this.dateRangeForm.invalid) {
      return;
    }
    const discount_rules = this.periods.value.map((p: any) => {
      if (typeof p.dateRange === 'string') {
        // When dateRange is a string, split it by " - "
        const [start, end] = p.dateRange.split(' - ');
        return {
          start_date: start,
          end_date: end
        };
      } else if (p.dateRange && p.dateRange.startDate && p.dateRange.endDate) {
        // When dateRange is an object with Moment objects
        return {
          start_date: p.dateRange.startDate.format('YYYY-MM-DD'),
          end_date: p.dateRange.endDate.format('YYYY-MM-DD')
        };
      } else {
        // Fallback if dateRange is in an unexpected format
        return {
          start_date: null,
          end_date: null
        };
      }
    });

    if (form.valid) {
      const payload = {
        name: form.value.name,
        rating: form.value.rating,
        superior_hotel: form.value.superior_hotel,
        country_id: form.value.country,
        currency: form.value.currency,
        region_id: form.value.region,
        city: form.value.city,
        street: form.value.street,
        number: form.value.house_number,
        postal_code: form.value.postal_code,
        website: form.value.website,
        hotel_type: form.value.hotel_type,
        checkin_from: form.value.checkin_from,
        checkin_till: form.value.checkin_till,
        checkin_full_time: form.value.checkin_full_time,
        checkout_till: form.value.checkout_till,
        phone_requests: form.value.phone_requests,
        availability_close_time: form.value.availability_close_time ? form.value.availability_close_time : 'null',
        updated_at: new Date().toISOString(),
        discounted_nights: form.value.discounted_nights,
        discount_rules: discount_rules
      };
      console.log(payload);

       // Make the service call to save the hotel contact
       this.hotelService.saveHotelinfo(payload).subscribe(
        (response: any) => {
          this.hotel = response.data;
          //sort the date ranges
          this.periods.clear();
          const sortedRules = this.hotel.discount_rules.sort((a: any, b: any) => {
            const dateA = moment(a.start_date, 'YYYY-MM-DD');
            const dateB = moment(b.start_date, 'YYYY-MM-DD');
            return dateA.diff(dateB);
          });
          sortedRules.forEach((rule: any) => {
            const dateRangeValue = rule.start_date && rule.end_date
              ? {
                  startDate: moment(rule.start_date, 'YYYY-MM-DD'),
                  endDate: moment(rule.end_date, 'YYYY-MM-DD')
                }
              : null;
            this.periods.push(
              this.fb.group({
                dateRange: [dateRangeValue, Validators.required]
              })
            );
          });
          this.successMessage = 'Hotel information saved successfully';
          console.log("Success");
        },
        (error) => {
          this.successMessage = '';
          console.error("Failed", error);
        }
      );
      this.showSuccess('Hotel information saved successfully');
    } else {
      console.log('Form is invalid');
      this.showError('Form is invalid');
    }
  }

  getDefaultCountryIndex(): number | undefined {
    return this.countries.findIndex(country =>
      ['Switzerland', 'France'].includes(country.name)
    );
  }

  onCountryChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const countryIndex = this.countries.findIndex(country => country.id === +selectElement.value);
    if (countryIndex !== undefined) {
      this.fetchRegionsByCountry(countryIndex);
    }
  }

  addPeriod(dateRange: string = ''): void  {
    const periods = this.dateRangeForm.get('periods') as FormArray;
    periods.push(
      this.fb.group({
        dateRange: [dateRange ? dateRange : null, Validators.required]
      })
    );
    this.checkOverlap();
  }

  removePeriod(index: number): void {
    this.periods.removeAt(index);
    this.checkOverlap();
  }

  onDateChange(range: { startDate: moment.Moment; endDate: moment.Moment }, index: number): void {
    if (!range.startDate || !range.endDate) {
      return;
    }
    const formattedRange = `${range.startDate.format('YYYY-MM-DD')} - ${range.endDate.format('YYYY-MM-DD')}`;
    this.periods.at(index).patchValue({ dateRange: formattedRange });

    this.checkOverlap();
  }

  checkOverlap(): void {
    // Map each control's value to an object with Moment dates.
    const parsedDates = this.periods.controls.map((control) => {
      const range = control.get('dateRange')?.value;
      if (!range) {
        return null;
      }
      // If it's a string (fallback)
      if (typeof range === 'string') {
        const parts = range.split(' - ');
        if (parts.length !== 2) { return null; }
        return { start: moment(parts[0], 'YYYY-MM-DD'), end: moment(parts[1], 'YYYY-MM-DD') };
      }
      // Otherwise, assume it's an object.
      // Prefer startDate/endDate if available.
      const start = range.startDate ? moment(range.startDate) : (range.start ? moment(range.start) : null);
      const end = range.endDate ? moment(range.endDate) : (range.end ? moment(range.end) : null);
      return { start, end };
    });

    // Clear previous errors.
    this.periods.controls.forEach(control => control.setErrors(null));

    // For each control, check if its range overlaps any other.
    this.periods.controls.forEach((control, index) => {
      const current = parsedDates[index];
      // Check that current and its start/end are not null
      if (!current || !current.start || !current.end) {
        control.setErrors(null);
        return;
      }

      // Assign to local variables with non-null assertion
      const currentStart = current.start;
      const currentEnd = current.end;
      const hasOverlap = parsedDates.some((other, i) => {
        if (i === index || !other || !other.start || !other.end) {
          return false;
        }
        // Compare using Moment's isAfter and isBefore methods
        return !currentStart.isAfter(other.end) && !currentEnd.isBefore(other.start);
      });

      if (hasOverlap) {
        control.setErrors({ overlap: true });
      } else {
        control.setErrors(null);
      }
    });
  }

  // Display a success message
  showSuccess(message: string): void {
    this.successMessage = message;
    setTimeout(() => this.dismissSuccess(), 2000);
  }

  // Dismiss success message
  dismissSuccess(): void {
    this.successMessage = '';
  }
  // Display an error message
  showError(message: string): void {
    this.error = message;
    setTimeout(() => this.dismissError(), 2000);
  }

  // Dismiss error message
  dismissError(): void {
    this.error = '';
  }
  setLocation() {
    // if (!this.plusCode) {
    //   alert('Please enter a valid Plus Code');
    //   return;
    // }

    console.log('Geocoding Plus Code:', this.plusCode); // Debugging log

    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: this.plusCode }, (results, status) => {
      console.log('Geocoding response:', status, results); // Debugging log

      if (status === 'OK' && results && results.length > 0) {
        const location = results[0].geometry.location;
        this.center = { lat: location.lat(), lng: location.lng() };
        this.markerPosition = this.center;

        console.log('Location found:', this.center); // Debugging log
      } else {
        console.error('Geocoding failed:', status, results);
        alert('Invalid Plus Code or location not found.');
      }
    });
  }
}
