import { Injectable, Inject, InjectionToken } from '@angular/core';
import { HttpService } from './http.service';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { CookiesService } from './cookies.service';
import { Hotel } from '../interfaces/hotel';
import { BookingResponse } from '../interfaces/booking-response';
import { Languages } from '../interfaces/languages';

export const HOTEL_SERVICE_TOKEN = new InjectionToken<HotelService>('HotelService');

@Injectable({
  providedIn: 'root',
})
export class HotelService {
  private selectedHotelKey = 'selectedHotel';
  private selectedHotelSubject = new BehaviorSubject<Hotel | null>(null);
  selectedHotel$ = this.selectedHotelSubject.asObservable();

  constructor(
    private http: HttpService,
    private cookieService: CookiesService
  ) {
    this.initializeSelectedHotel();
  }

  private initializeSelectedHotel(): void {
    const selectedHotelId = this.getSelectedHotel();
    if (selectedHotelId) {
      this.getHotel(selectedHotelId).subscribe((response: { data: Hotel }) => {
        if (response && response.data) {
          this.selectedHotelSubject.next(response.data);
        }
      }, error => {
        console.error('Error fetching selected hotel', error);
      });
    }
  }

  getHotels(filters: any): Observable<any> {
    return this.http.get('v1/hotels', { params: filters });
  }

  getHotel(id: string | number): Observable<{ data: Hotel }> {
    return this.http.get(`v2/hotels/information/${id}`);
  }
  saveHotel(id: string | number, data: any){
    return this.http.put(`v2/hotels/${id}`, data).pipe(
      map(response => {
        if (response && response.data) {
          return response;
        } else {
          return null;
        }
      }),
      catchError(error => {
        console.error('Error fetching selected hotel bookings', error);
        return of(null);
      })
    );
  }

  getBookings(id: string | number, params: any): Observable<BookingResponse> {
    return this.http.get(`v1/bookings/all/${id}`, { params });
  }

  getSelectedHotelBookings(params: any): Observable<BookingResponse | null> {
    const selectedHotel = this.getSelectedHotel();
    if (!selectedHotel) return of(null);

    return this.getBookings(selectedHotel, params).pipe(
      map(response => {
        if (response && response.data) {
          return response;
        } else {
          return null;
        }
      }),
      catchError(error => {
        console.error('Error fetching selected hotel bookings', error);
        return of(null);
      })
    );
  }

  getSelectedHotelObject(): Observable<Hotel | null> {
    const selectedHotel = this.getSelectedHotel();
    if (!selectedHotel) return of(null);

    return this.getHotel(selectedHotel).pipe(
      map(response => response.data), // Extract the data property
      tap(hotel => {
        if (hotel) {
          this.selectedHotelSubject.next(hotel);
        }
      }),
      catchError(error => {
        console.error('Error fetching selected hotel', error);
        return of(null);
      })
    );
  }

  getSelectedHotel(): string | null {
    return this.cookieService.get(this.selectedHotelKey);
  }

  setSelectedHotel(hotelId: string): void {
    this.cookieService.update(this.selectedHotelKey, hotelId);
    this.getHotel(hotelId).subscribe((response: { data: Hotel }) => {
      if (response && response.data) {
        this.selectedHotelSubject.next(response.data);
      }
    }, error => {
      console.error('Error fetching selected hotel', error);
    });
  }

  clearSelectedHotel(): void {
    this.selectedHotelSubject.next(null);
    this.cookieService.update(this.selectedHotelKey, '');
  }
  deleteSelectedImage(hotelId: string, imageId: string) {
    const selectedHotel = this.getSelectedHotel();
    return this.http.delete(`v1/hotels/${hotelId}/images${imageId}`, selectedHotel);
  }
  getLanguages(): Observable<Languages | null>  {
    const test =  this.http.get('v1/configs/languages').pipe(
      map(response => {
        if (response && response.data) {
          return response;
        } else {
          return null;
        }
      }),
      catchError(error => {
        console.error('Error fetching the languages', error);
        return of(null);
      })
    );
    return test;
  }
  getHotelInfo() {
    const selectedHotel = this.getSelectedHotel();
    return this.http.get(`v2/hotels/information/${selectedHotel}`);
  }

  saveHotelinfo(data: any) {
    const selectedHotel = this.getSelectedHotel();
    return this.http.put(`v2/hotels/information/${selectedHotel}`, data);
  }

  getDescription() {
    const selectedHotel = this.getSelectedHotel();
    return this.http.get(`v2/hotels/description/${selectedHotel}`);
  }

  saveDescription(data: any) {
    const selectedHotel = this.getSelectedHotel();
    return this.http.put(`v2/hotels/description/${selectedHotel}`, data);
  }
}
