import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FeatureService } from '../../core/services/feature.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent implements OnInit, OnDestroy {
  breadCrumbItems!: Array<{}>;
  public hotelActivities: Array<any> = [];
  public hotelfeatures: Array<any> = [];
  public activities: Array<any> = [];
  selectAll: boolean = false;
  noActivities: boolean = false;
  success: boolean = false;
  error: string = '';
  private refreshSubscription!: Subscription;

  constructor(
    private featureService: FeatureService
  ) { }

  ngOnInit(): void {
    this.initializeBreadcrumbs();
    this.subscribeToHotelActivities();
    // this.fetchActivities();
    this.checkAllActivitiesStatus();
  }
  ngOnDestroy(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }
  initializeBreadcrumbs(): void {
    this.breadCrumbItems = [
      { label: 'Hotel' },
      { label: 'Activities', active: true }
    ];
  }
  subscribeToHotelActivities(): void {
    this.featureService.getHotelActivities().subscribe((result: any) => {
      this.hotelActivities = result.data;
      this.fetchActivities();
    });
  }

  fetchActivities(): void {
    this.featureService.getActivities().subscribe((result: any) => {
      this.activities = result.data.activities;
      this.activities.forEach(activity => {
        const hotelActivity = this.hotelActivities.find((hotelFeature: any) => hotelFeature.id === activity.id);
        if (hotelActivity) {
          activity.is_active = hotelActivity.is_active;
        }
      });
      this.updateSelectAllStatus();
    });
  }

  checkAllActivitiesStatus() {
    this.selectAll = this.activities.every(activity => activity.is_active);
  }

  onSubmit(form: NgForm) {
    if (form.valid) {
      const selectedActivities = this.activities.filter(activity => activity.is_active).map(choice => ([choice.id]));
      const result = {
        "activities": selectedActivities.map(String)
      };
      this.featureService.saveActivities(result).subscribe(
        (response: any) => {
          this.success = true;
          setTimeout(() => {
            this.error = '';
          }, 2000);
        },
        (error) => {
          this.error = 'There was an error saving the activities. Please try again.';
          setTimeout(() => {
            this.error = '';
            this.success = false;
          }, 2000);
        }
      );
      form.resetForm();
    }
  }

  toggleSelectAll(): void {
    const newStatus = !this.selectAll;
    this.activities.forEach(activity => activity.is_active = newStatus);
    this.selectAll = newStatus;
  }

  toggleActivityStatus(activity: any): void {
    activity.is_active = !activity.is_active;
    this.updateSelectAllStatus();
  }

  updateSelectAllStatus(): void {
    if (this.activities.length === 0) {
      this.selectAll = false;
    } else {
      this.selectAll = this.activities.every(activity => activity.is_active);
    }
  }

  removeAmenity(activity: any): void {
    this.hotelActivities = this.hotelActivities.filter(a => a !== activity);
    if (this.hotelActivities.length === 0) {
      this.selectAll = false;
      this.noActivities = true;
    } else {
      this.noActivities = false;
    }
  }
}
