import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { BehaviorSubject } from 'rxjs';
import { CookiesService } from './cookies.service';
import { Hotel } from '../interfaces/hotel';

@Injectable({
  providedIn: 'root'
})
export class HotelSettingService {
  private selectedHotelKey = 'selectedHotel';
  private selectedHotelSubject = new BehaviorSubject<Hotel | null>(null);
  selectedHotel$ = this.selectedHotelSubject.asObservable();
  public selectedHotelId: number = 0;

  constructor(
    private http: HttpService,
    private cookieService: CookiesService
  ) { }

  getSelectedHotel(): string | null {
    return this.cookieService.get(this.selectedHotelKey);
  }

  getHotelSettings() {
    const selectedHotelId = this.getSelectedHotel();
    return this.http.get(`v2/hotels/settings/${selectedHotelId}`);
  }

  saveHotelSettings(data: any) {
    const selectedHotelId = this.getSelectedHotel();
    return this.http.put(`v2/hotels/settings/${selectedHotelId}`, data);
  }

  deleteHotelSettings(nid: number) {
    const selectedHotelId = this.getSelectedHotel();
    return this.http.delete(`v2/hotels/settings/${selectedHotelId}/notes/${nid}`);
  }

  getLabelsList() {
    return this.http.get(`v2/labels`);
  }

  getChannelManagersList() {
    return this.http.get(`v2/channel-managers`);
  }
}
