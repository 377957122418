<!-- ========== Left Sidebar Start ========== -->
<div class="vertical-menu">

  <!-- LOGO -->
  <div class="navbar-brand-box">
    <a routerLink="/" class="logo logo-dark">
      <span class="logo-sm">
        <img src="../../../assets/images/logo-white.png" alt="" height="22"/>
      </span>
      <span class="logo-lg">
        <img src="../../../assets/images/logo.svg" alt="" height="22"/>
      </span>
    </a>
  </div>

  <button type="button" class="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn" (click)="toggleMobileMenu($event)">
    <i class="fa fa-fw fa-bars"></i>
  </button>

  <!--- Sidemenu -->
  <ngx-simplebar class="sidebar-menu-scroll" style="max-height: 100vh">
    <div id="sidebar-menu">
      <ul class="metismenu list-unstyled" id="side-menu">
        <ng-container *ngFor="let item of menuItems; trackBy: trackByIndex">
          <ng-container *ngIf="!item.admin || (item.admin && ac.isAdmin())">
            <li *ngIf="item.isTitle" class="menu-title">
              {{ item.label | translate }}
            </li>
            <li *ngIf="!item.isTitle && !item.isLayout">
              <ng-container *ngIf="hasItems(item); else singleItem">
                <a href="javascript:void(0);" class="is-parent" [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">
                  <img src="{{ item.icon }}" class="nav-icon-me nav-icon"/>
                  <span class="menu-item">{{ item.label | translate }}</span>
                  <span *ngIf="item.badge" class="badge rounded-pill float-end bg-{{ item.badge.variant }}">{{ item.badge.text | translate }}</span>
                </a>
                <ul class="sub-menu" aria-expanded="false">
                  <ng-container *ngFor="let subitem of item.subItems; trackBy: trackByIndex">
                    <li>
                      <ng-container *ngIf="hasItems(subitem); else singleSubItem">
                        <a class="side-nav-link-a-ref has-arrow" href="javascript:void(0);" [attr.data-parent]="subitem.parentId">
                          {{ subitem.label | translate }}
                        </a>
                        <ul class="sub-menu mm-collapse" aria-expanded="false">
                          <ng-container *ngFor="let subSubitem of subitem.subItems; trackBy: trackByIndex">
                            <li>
                              <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link" routerLinkActive="active" class="side-nav-link-ref">
                                {{ subSubitem.label | translate }}
                              </a>
                            </li>
                          </ng-container>
                        </ul>
                      </ng-container>
                      <ng-template #singleSubItem>
                        <a [routerLink]="subitem.link" class="side-nav-link-ref" [attr.data-parent]="subitem.parentId" routerLinkActive="active">
                          {{ subitem.label | translate }}
                        </a>
                      </ng-template>
                    </li>
                  </ng-container>
                </ul>
              </ng-container>
              <ng-template #singleItem>
                <a [routerLink]="item.link" class="side-nav-link-ref" routerLinkActive="active">
                  <img *ngIf="item.icon" src="{{ item.icon }}" class="nav-icon-me nav-icon"/>
                  <span class="menu-item">{{ item.label | translate }}</span>
                  <span *ngIf="item.badge" class="badge rounded-pill {{ item.badge.variant }} float-end">{{ item.badge.text | translate }}</span>
                </a>
              </ng-template>
            </li>
          </ng-container>
        </ng-container>
      </ul>
    </div>
  </ngx-simplebar>
  <!-- <app-footer></app-footer> -->
</div>
<!-- Left Sidebar End -->
