import { Component, OnInit, OnDestroy, HostListener, AfterViewInit, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { SeoContentService } from '../../core/services/seo-content.service';
import { NgForm } from '@angular/forms';
import { LanguageService } from '../../core/services/language.service';
import { HotelService } from '../../core/services/hotel.service';
import { Hotel } from '../../core/interfaces/hotel';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-seo-content',
  templateUrl: './seo-content.component.html',
  styleUrls: ['./seo-content.component.scss']
})
export class SeoContentComponent implements OnInit, OnDestroy,AfterViewInit {
  breadCrumbItems!: Array<{}>;
  seoContent: any = {};
  metaDescription: any = {};
  metaTitle: any = {};
  hotel: any = {};
  public hotel$: Observable<Hotel | null>;
  successMessage = '';
  error = '';
  languages = [];
  private refreshSubscription!: Subscription;
  @ViewChildren('textareaElement') textareaElements: QueryList<ElementRef> | null = null;
  @ViewChildren('textareaTitleElement') textareaTitleElements: QueryList<ElementRef> | null = null;

  constructor(
    private seoContentService: SeoContentService,
    private languageService: LanguageService,
    private hotelService: HotelService
  ) {
    this.hotel$ = this.hotelService.selectedHotel$;
  }

  ngOnInit() {
    this.hotelService.getSelectedHotelObject().subscribe();
    this.initializeBreadcrumbs();
    this.subscribeToSelectedHotel();
    this.subscribeToSeoContent();
    this.getlanguages();
    setTimeout(() => {
      this.adjustTextareaHeight();
      this.adjustTextareaTitleHeight();
    }, 1000);
  }
  ngOnDestroy(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }
  ngAfterViewInit() {
    this.adjustTextareaHeight();
    this.adjustTextareaTitleHeight();
  }

  initializeBreadcrumbs(): void {
    this.breadCrumbItems = [
      { label: 'Hotels' },
      { label: 'Hotel SEO Content', active: true }
    ];
  }
  // Subscribe to selected hotel data
  subscribeToSelectedHotel(): void {
    this.hotelService.selectedHotel$.subscribe((hotel: any) => {
      if (hotel) {
        this.hotel = hotel;
        this.subscribeToSeoContent();
      }
    });
  }

  subscribeToSeoContent(): void {
    this.seoContentService.getSeo().subscribe( result  => {
      if (result) {
        this.metaDescription = result.data.meta_description;
        this.metaTitle = result.data.meta_title;
      }
    });
  }

  getlanguages() {
    this.languageService.getlanguages().subscribe( result  => {
      if (result) {
        this.languages = result.data;
        this.initializeSeoContentForLanguages();
      }
    });
  }
  initializeSeoContentForLanguages() {
    this.languages.forEach((language: any) => {
      // Set default empty value for each language
      if (!this.metaTitle[language.code]) {
        this.metaTitle[language.code] = '';
      }
      if (!this.metaDescription[language.code]) {
        this.metaDescription[language.code] = '';
      }
    });
  }
  @HostListener('input', ['$event.target'])
  onTitleInput(e: any): void {
    this.adjustTextareaTitleHeight();
  }
  onInput(e: any): void {
    this.adjustTextareaHeight();
  }
  adjustTextareaHeight(): void {
    // Check if textareaElements is populated
    if (this.textareaElements) {
      this.textareaElements.forEach((textarea) => {
        const element = textarea.nativeElement;
        element.style.height = 'auto';
        element.style.height = `${element.scrollHeight}px`;
      });
    }
  }
  adjustTextareaTitleHeight(): void {
    // Check if textareaElements is populated
    if (this.textareaTitleElements) {
      this.textareaTitleElements.forEach((textarea) => {
        const element = textarea.nativeElement;
        element.style.height = 'auto';
        element.style.height = `${element.scrollHeight}px`;
      });
    }
  }

  onSubmit(form: NgForm) {
      const meta_title:any = {};
      const meta_description:any = {};

      this.languages.forEach((language:any) => {
        meta_title[language.code] = form.value[`metaTitle${language.code}`];
        meta_description[language.code] = form.value[`metaDescription${language.code}`];
      });
      const payload = {
        meta_title: meta_title,
        meta_description: meta_description,
      };
      this.seoContentService.saveSeo(payload).subscribe(
        (response: any) => {
          this.successMessage = response.message;
          console.log("Success");
        },
        (error) => {
          this.successMessage = error.message;
          console.error("Failed", error);
        }
      );
      this.adjustTextareaHeight();
  }

  showSuccess(message: string): void {
    this.successMessage = message;
    setTimeout(() => this.dismissSuccess(), 2000);
  }

  dismissSuccess(): void {
    this.successMessage = '';
  }

  showError(message: string): void {
    this.error = message;
    setTimeout(() => this.dismissError(), 2000);
  }

  dismissError(): void {
    this.error = '';
  }
}
