import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from './http.service';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  private currentLangSubject = new BehaviorSubject<string>('en');
  currentLang$ = this.currentLangSubject.asObservable();

  public languages: string[] = [];

  constructor(
    public translate: TranslateService,
    private cookieService: CookieService,
    private http: HttpService
  ) {

    let browserLang:any;
    this.translate.addLangs(this.languages);

    if (this.cookieService.check('lang')) {
      browserLang = this.cookieService.get('lang');
    }
    else {
      browserLang = translate.getBrowserLang();
    }
    translate.use(browserLang.match(/en|de|fr|it/) ? browserLang : 'en');
  }

  /***
   * Cookie Language set
   */
  public setLanguage(lang: any) {
    this.translate.use(lang);
    this.cookieService.set('lang', lang);
  }
  getlanguages() {
    return this.http.get(`v2/languages`);
  }
}
