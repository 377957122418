<div *ngIf="error" class="alert alert-danger alert-dismissible fade show mt-3">
    <i class="uil uil-exclamation-triangle me-2"></i>
    <span class="ms-2">
      {{ error }}
    </span>
    <button type="button" class="btn-close mt-1" aria-label="Close" (click)="dismissError()"></button>
</div>

<div *ngIf="successMessage" class="alert alert-success alert-dismissible fade show mt-3">
    <i class="uil uil-check me-2"></i>
    <span class="ms-2">
      {{ successMessage }}
    </span>
    <button type="button" class="btn-close mt-1" aria-label="Close" (click)="dismissSuccess()"></button>
</div>
<!-- start page title -->
<app-pagetitle title="Hotel Settings" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->
<form #settingsForm="ngForm" (ngSubmit)="onSubmit(settingsForm)">
    <div class="row mt-3">
      <!-- Hotel Status Section -->
      <div class="col-12">
        <div class="card card-h-100">
          <div class="card-body card-manager-body">
            <div class="row">
                <div class="col-md-2">
                    <label class="hotel-settings-status" for="hotel-status">Hotel status</label>
                </div>
                <div class="col-md-10">
                    <div class="d-flex">
                        <div class="form-check mb-2">
                            <input class="form-check-input" type="radio" name="hotel-status" id="status-published" [checked]="settings.is_published == 1">
                            <label class="form-check-label hotel-status" for="status-published">
                                Published
                            </label>
                        </div>
                        <div class="form-check ms-3">
                            <input class="form-check-input" type="radio" name="hotel-status" id="status-hidden" [checked]="settings.hide_hotel == 1">
                            <label class="form-check-label hotel-status" for="status-hidden">
                                Hidden
                            </label>
                        </div>
                        <div class="form-check ms-3">
                            <input class="form-check-input" type="radio" name="hotel-status" id="status-offline" [checked]="settings.hotel_status == 0">
                            <label class="form-check-label hotel-status" for="status-offline">
                                Offline
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-2">
                    <label class="hotel-members-only" for="members-only">Members only</label>
                </div>
                <div class="col-md-10 mt-2">
                    <div class="form-check hotel-info-superior form-switch form-switch-md mb-2" dir="ltr">
                        <input type="checkbox" class="form-check-input members-only mt-1" id="members-only" name="members-only" [(ngModel)]="hotel.superior_hotel">
                        <label class="form-check-label discount-hotel-info till-late ms-1" for="members-only">No</label>
                    </div>
                </div>
            </div>
          </div>
        </div><!-- end card -->
      </div><!-- end col -->

      <!-- Hotel Total Score Section -->
      <div class="col-12">
        <div class="card card-h-100">
          <div class="card-body card-manager-body">
            <div class="row">
                <div class="col-md-2">
                    <label class="hotel-settings-total-score" for="hotel-total-score">Hotel Total Score</label>
                </div>
                <div class="col-md-3">
                    <input type="text" class="form-control form-input-bg hotal-total-score" id="total-score" name="total-score" [(ngModel)]="settings.internal_ranking" [value]="settings.internal_ranking" #totalScore="ngModel" required>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-2">
                    <label class="hotel-settings-trust-you" for="hotel-trust-you">TrustYOU</label>
                </div>
                <div class="col-md-3 mt-2">
                    <input type="text" class="form-control form-input-bg hotel-trust-you" id="trust-you" name="trust-you" [(ngModel)]="settings.trust_you" [value]="settings.trust_you" #trustYou="ngModel" required>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-2">
                    <label class="hotel-user-rating" for="settings-user-rating">User rating</label>
                </div>
                <div class="col-md-3 mt-3" *ngIf="settings.user_rating != null">
                    <input type="text" class="form-control user-rating" id="user-rating" name="user-rating" [(ngModel)]="settings.user_rating" [value]="settings.user_rating" #userRating="ngModel" required>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-2">
                    <label class="hotel-settings-labels" for="labels">Labels</label>
                </div>
                <div class="col-md-8 mt-2">
                    <div class="row">
                      <div class="col-auto" *ngFor="let label of labels; let index = index;">
                        <div class="form-check text-room-desc form-room-check" id="categories-pill">
                          <input
                            class="form-check-input cursor-pointer"
                            type="checkbox"
                            [id]="'check' + index"
                            [(checked)]="label.is_active"
                            (change)="toggleLabelStatus(label)"
                            [ngClass]="{'active': label.is_active, 'inactive': !label.is_active}"
                            style="display: none;">

                          <label
                            class="form-check-label cursor-pointer categories"
                            [ngClass]="{'active': label.is_active, 'inactive': !label.is_active}"
                            [for]="'check' + index">
                            {{ label.name }}
                          </label>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
          </div>
        </div><!-- end card -->
      </div><!-- end col -->

      <!-- Booking Mode Section -->
      <div class="col-12">
        <div class="card card-h-100">
          <div class="card-body card-manager-body">
            <div class="row">
                <div class="col-md-2">
                    <label class="booking-mode" for="hotel-booking-mode">Booking mode</label>
                </div>
                <div class="col-md-10">
                    <div class="d-flex">
                        <div class="form-check mb-2">
                            <input class="form-check-input" type="radio" name="hotel-booking-mode" id="status-manual" checked>
                            <label class="form-check-label hotel-booking-mode" for="status-manual">
                                Manual
                            </label>
                        </div>
                        <div class="form-check ms-3">
                            <input class="form-check-input" type="radio" name="hotel-booking-mode" id="status-channel-manager">
                            <label class="form-check-label hotel-booking-mode" for="status-channel-manager">
                                Channel manager
                            </label>
                        </div>
                    </div>
                </div>
            </div>
                        <div class="row mt-2">
                <div class="col-md-2">
                    <label class="channel-manager" for="hotel-booking-mode">Channel manager</label>
                </div>
                <div class="col-md-10">
                    <div class="d-flex">
                        <div class="form-check mb-2">
                            <input class="form-check-input" type="radio" name="channel-manager" id="hotel-spider" checked>
                            <label class="form-check-label hotel-booking-mode" for="hotel-spider">
                                HotelSpider
                            </label>
                        </div>
                        <div class="form-check ms-3">
                            <input class="form-check-input" type="radio" name="channel-manager" id="yield-planet">
                            <label class="form-check-label hotel-booking-mode" for="yield-planet">
                                Yield-Planet
                            </label>
                        </div>
                        <div class="form-check ms-3">
                            <input class="form-check-input" type="radio" name="channel-manager" id="vertical-bookings">
                            <label class="form-check-label hotel-booking-mode" for="vertical-bookings">
                                Vertical-Bookings
                            </label>
                        </div>
                        <div class="form-check ms-3">
                            <input class="form-check-input" type="radio" name="channel-manager" id="kognitiv">
                            <label class="form-check-label hotel-booking-mode" for="kognitiv">
                                Kognitiv
                            </label>
                        </div>
                        <div class="form-check ms-3">
                            <input class="form-check-input" type="radio" name="channel-manager" id="ratedock">
                            <label class="form-check-label hotel-booking-mode" for="ratedock">
                                RateDock
                            </label>
                        </div>
                        <div class="form-check ms-3">
                            <input class="form-check-input" type="radio" name="channel-manager" id="dirs21">
                            <label class="form-check-label hotel-booking-mode" for="dirs21">
                                DIRS21
                            </label>
                        </div>
                        <div class="form-check ms-3">
                            <input class="form-check-input" type="radio" name="channel-manager" id="none">
                            <label class="form-check-label hotel-booking-mode" for="none">
                                none
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-2">
                    <label class="channel-logs" for="channel-logs">Channel manager logs</label>
                </div>
                <div class="col-md-10 mt-2">
                    <div class="col-md-10 mt-2">
                        <div class="form-check hotel-info-superior form-switch form-switch-md mb-2" dir="ltr">
                            <input type="checkbox" class="form-check-input available-notify mt-1" id="channel-logs" name="available-notify" checked>
                            <label class="form-check-label discount-hotel-info till-late ms-1" for="channel-logs">Recorded</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-2">
                    <label class="hotel-availability-notifications" for="hotel-availability-notifications">Availability notifications</label>
                </div>
                <div class="col-md-10 mt-2">
                    <div class="col-md-10 mt-2">
                        <div class="form-check hotel-info-superior form-switch form-switch-md mb-2" dir="ltr">
                            <input type="checkbox" class="form-check-input available-notify mt-1" id="available-notify" name="available-notify">
                            <label class="form-check-label discount-hotel-info till-late ms-1" for="available-notify">Disabled</label>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div><!-- end card -->
      </div><!-- end col -->

      <!-- Yield price planning Section -->
      <div class="col-12">
        <div class="card card-h-100">
            <div class="card-body card-manager-body">
                <div class="row">
                     <div class="col-md-2">
                        <label class="hotel-yield-price" for="yield-price">Yield price planning</label>
                    </div>
                    <div class="col-md-10">
                        <div class="form-check hotel-info-superior form-switch form-switch-md mb-2 mt-2" dir="ltr">
                            <input type="checkbox" class="form-check-input available-notify mt-2" id="yield-price" name="yield-price" [checked]="settings.yield_price == 1">
                            <label class="form-check-label discount-hotel-info till-late ms-1" for="yield-price">Active</label>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-2">
                        <label class="hotel-last-minute-yield" for="last-minute-yielding">Last minute Yielding</label>
                    </div>
                    <div class="col-md-10 mt-2">
                        <div class="form-check hotel-info-superior form-switch form-switch-md mb-2 mt-1" dir="ltr">
                            <input type="checkbox" class="form-check-input available-notify mt-1" id="last-minute-yielding" name="last-minute-yielding" [checked]="settings.last_minute_yielding == 1">
                            <label class="form-check-label discount-hotel-info till-late ms-1" for="last-minute-yielding">Disabled</label>
                        </div>
                    </div>
                </div>
            </div>
        </div><!-- end card -->
      </div><!-- end col -->

      <!-- Minimum length of stay Section -->
      <div class="col-12">
        <div class="card card-h-100">
            <div class="card-body card-manager-body">
                <div class="row mt-3">
                    <div class="col-md-2">
                        <label class="mininum-length-stay text-nowrap" for="mininum-length-stay">Minimum length of stay</label>
                    </div>
                    <div class="col-md-10 mt-2 position-relative switch-minimum-length">
                        <div class="form-check hotel-info-superior form-switch form-switch-md mb-2" dir="ltr">
                            <input type="checkbox" class="form-check-input available-notify mt-1" id="mininum-length-stay" name="mininum-length-stay" [(ngModel)]="isMinimumLengthActive">
                            <label class="form-check-label discount-hotel-info till-late ms-1" for="mininum-length-stay">{{ isMinimumLengthActive ? 'Active' : 'Disabled' }}</label>
                        </div>
                        <div *ngIf="isMinimumLengthActive">
                            <div *ngFor="let card of cards; let i = index" class="card mb-30">
                                <div class="card-body">
                                    <div class="row mb-30">
                                        <div class="col-md-6">
                                            <div class="row">
                                                <div class="col-md-8">
                                                    <h4 class="date-range-settings">Date range</h4>
                                                    <div class="input-container">
                                                        <input type="text"
                                                            class="form-control hotel-info"
                                                            formControlName="dateRange"
                                                            ngxDaterangepickerMd
                                                            name="dateRange"
                                                            [id]="'dynamic-daterange' + i"
                                                            placeholder="Select date range"
                                                            [autoApply]="true"
                                                            [locale]="{ format: 'DD.MM.YYYY' }"
                                                            #dateInput
                                                          />
                          
                                                        <label [for]="'dynamic-daterange' + i" class="calendar-icon-img" (click)="focusInput(dateInput)">
                                                          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                                              <path d="M14.75 3H4.25C3.42157 3 2.75 3.67157 2.75 4.5V15C2.75 15.8284 3.42157 16.5 4.25 16.5H14.75C15.5784 16.5 16.25 15.8284 16.25 15V4.5C16.25 3.67157 15.5784 3 14.75 3Z" stroke="#495057" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                              <path d="M12.5 1.5V4.5" stroke="#495057" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                              <path d="M6.5 1.5V4.5" stroke="#495057" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                              <path d="M2.75 7.5H16.25" stroke="#495057" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                          </svg>
                                                        </label>
                                                      </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <h4 class="date-range-settings">Minimum nights</h4>
                                                    <input class="form-control" type="number" value="1" id="example-number-input" class="form-control">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-30">
                                        <div class="col-auto" *ngFor="let dayPeriod of dayPeriods; let index = index;">
                                          <div class="form-check text-room-desc form-room-check" id="categories-pill">
                                            <input
                                              class="form-check-input cursor-pointer"
                                              type="checkbox"
                                              [id]="'label-check' + index"
                                              [(checked)]="dayPeriod.is_active"
                                              (change)="toggleLabelSettingsStatus(dayPeriod)"
                                              [ngClass]="{'active': dayPeriod.is_active, 'inactive': !dayPeriod.is_active}"
                                              style="display: none;">
                  
                                            <label
                                              class="form-check-label cursor-pointer categories"
                                              [ngClass]="{'active': dayPeriod.is_active, 'inactive': !dayPeriod.is_active}"
                                              [for]="'label-check' + index">
                                              {{ dayPeriod.name }}
                                            </label>
                                          </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <h4 class="date-range-settings">Room category</h4>
                                            <div class="d-flex">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" id="formCheckAll" checked>
                                                    <label class="form-check-label settings-checkbox" for="formCheckAll">
                                                        All categories
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" id="formCheckRoomComfort">
                                                    <label class="form-check-label settings-checkbox" for="formCheckRoomComfort">
                                                        Double room comfort
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" id="formCheckJunior">
                                                    <label class="form-check-label settings-checkbox" for="formCheckJunior">
                                                        Double room junior suite
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="button" class="btn btn-primary submit_btn add-msg-btn cursor-pointer d-flex justify-content-center align-items-center position-relative text-nowrap text-center add-note-btn add-condition-btn" (click)="addCard()">
                                <i class="fas fa-plus fa-sm"></i> Add condition
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div><!-- end card -->
      </div><!-- end col -->

      <!-- Booking guarantee Section -->
      <div class="col-12">
        <div class="card card-h-100">
            <div class="card-body card-manager-body">
                <div class="row mt-3">
                    <div class="col-md-2">
                        <label class="booking-guarantee text-nowrap" for="hotel-availability-notifications">Booking guarantee</label>
                    </div>
                    <div class="col-md-10 mt-2 position-relative switch-minimum-length">
                        <div class="form-check hotel-info-superior form-switch form-switch-md mb-2" dir="ltr">
                            <input type="checkbox" class="form-check-input available-notify mt-1" id="booking-guaranteee" name="booking-guaranteee" [checked]="settings.booking_guarantee == 1">
                            <label class="form-check-label discount-hotel-info till-late ms-1" for="booking-guaranteee">Required</label>
                        </div>
                    </div>
                </div>
            </div>
        </div><!-- end card -->
      </div><!-- end col -->

      <!-- Registration Section -->
      <div class="col-12">
        <div class="card card-h-100">
            <div class="card-body card-manager-body">
                <div class="row mt-3">
                    <div class="col-md-2">
                        <label class="registration-settings text-nowrap" for="regsitration">Registration</label>
                    </div>
                    <div class="col-md-3 mt-2">
                        <input type="text" class="form-control user-rating register-input" id="regsitration" name="regsitration" [value]="settings.created_at" required>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-2">
                        <label class="registration-settings text-nowrap" for="first-activate">First activation</label>
                    </div>
                    <div class="col-md-3 mt-2">
                        <input type="text" class="form-control user-rating register-input" id="first-activate" name="first-activate" [value]="settings.first_activation" required>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-2">
                        <label class="registration-settings text-nowrap" for="last-activate">Last activation</label>
                    </div>
                    <div class="col-md-3 mt-2">
                        <input type="text" class="form-control user-rating register-input" id="last-activate" name="last-activate" [value]="settings.last_activation" required>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-2">
                        <label class="registration-settings text-nowrap" for="create-user-id">Created by user ID</label>
                    </div>
                    <div class="col-md-3 mt-2">
                        <input type="text" class="form-control user-rating register-input" id="create-user-id" name="create-user-id" [value]="settings.created_by" required>
                    </div>
                </div>
                <hr class="horizontal-space">
                <div class="row mt-3">
                    <div class="col-md-2">
                        <label class="registration-settings text-nowrap" for="hc-hotel-ids">HotelCard hotel ID</label>
                    </div>
                    <div class="col-md-3 mt-2">
                        <input type="text" class="form-control user-rating register-input" id="hc-hotel-ids" name="hc-hotel-ids" value="8967" required>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-2">
                        <label class="registration-settings text-nowrap" for="hotel-uuid">Hotel UUID</label>
                    </div>
                    <div class="col-md-10 mt-2">
                        <input type="text" class="form-control user-rating register-input" id="hotel-uuid" name="hotel-uuid" [value]="settings.uuid" required>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-2">
                      <label class="internal-notes text-nowrap" for="internal-notes">Internal notes</label>
                    </div>
                    <div class="col-md-10 mt-4">
                      <button type="button" class="btn btn-primary submit_btn add-msg-btn cursor-pointer d-flex justify-content-center align-items-center position-relative text-nowrap text-center add-note-btn" (click)="addNote()">
                        <i class="fas fa-plus fa-sm add-note-icon"></i> Add note
                      </button>
                      <div *ngFor="let note of notes; let i = index" class="note-container">
                        <div class="input-group mb-3 position-relative input-notes-text">
                        <textarea class="form-control textarea-notes d-flex align-items-start align-self-stretch" [(ngModel)]="note.text">This hotel has an older and a bit longer temporary note from Loris that should wrap to a new line.  It can be 12.05.2024 09:21 Andi Schudel... once the note is no longer relevant.
                        </textarea>
                        <div class="d-flex justify-content-end align-items-center">
                            <img src="../../../assets/images/delete.svg" alt="Delete" class="w-24 h-24 cursor-pointer" (click)="removeNote(i)">
                        </div>
                        </div>
                    </div>
                    </div>
                  </div>
            </div>
        </div><!-- end card -->
      </div><!-- end col -->

    </div><!-- end row -->

    <div class="mt-4">
      <button type="button" class="btn btn-link text-decoration-none cancel_btn float-end cancel_btn_hotel_managers w-md">Cancel</button>
      <button type="submit" class="btn btn-primary float-md-end submit_btn submit_btn_border submit-manager-btn position-absolute w-md d-flex justify-content-center align-items-center">Save changes</button>
   </div>
    <br>
</form>
