import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CookiesService } from './cookies.service';
import { Hotel } from '../interfaces/hotel';

@Injectable({
  providedIn: 'root'
})
export class RoomService {
  private selectedHotelKey = 'selectedHotel';
  private selectedHotelSubject = new BehaviorSubject<Hotel | null>(null);
  selectedHotel$ = this.selectedHotelSubject.asObservable();
  public selectedHotelId: number = 0;

  constructor(
    private http: HttpService,
    private cookieService: CookiesService
  ) { }

  getSelectedHotel(): string | null {
    return this.cookieService.get(this.selectedHotelKey);
  }
  getAllRooms() {
    const selectedHotelId = this.getSelectedHotel();
    return this.http.get(`v1/hotels/${selectedHotelId}/rooms`).pipe(
      map(result => {
        if (!result || result.error) {
          return [];
        }
        return result.data;
      })
    );
  }
  getRoomDetails(id: number): Observable<any> {
    const selectedHotelId = this.getSelectedHotel();
    return this.http.get(`v1/hotels/${selectedHotelId}/rooms/${id}`);
  }

  getFeatures() {
    return this.http.get(`v1/features`);
  }
  getActivities() {
    return this.http.get(`v1/activities`);
  }
  addRoom(data: any) {
    const selectedHotelId = this.getSelectedHotel();
    return this.http.post(`v1/hotels/${selectedHotelId}/rooms`, data).pipe(
      map(response => {
        if (response && response.data) {
          return response;
        } else {
          return null;
        }
      }),
      catchError(error => {
        console.error('could not save the new room', error);
        return of(null);
      })
    );
  }
  deleteRoom(id: any) {
    const selectedHotelId = this.getSelectedHotel();
    return this.http.delete(`v1/hotels/${selectedHotelId}/rooms/${id}`).pipe(
      map(response => {
        if (response && response.data) {
          return response;
        } else {
          return null;
        }
      }),
      catchError(error => {
        return error;
      })
    );
  }
  editRoom(id: any, data: any) {
    const selectedHotelId = this.getSelectedHotel();
    return this.http.put(`v1/hotels/${selectedHotelId}/rooms/${id}`, data).pipe(
      map(response => {
        if (response && response.data) {
          return response;
        } else {
          return null;
        }
      }),
      catchError(error => {
        return error;
      })
    );
  }
  imageUpload(id: any, data: any) {
    const selectedHotelId = this.getSelectedHotel();
    return this.http.post(`v1/hotels/${selectedHotelId}/rooms/${id}/images`, data).pipe(
      map(response => {
        if (response && response.data) {
          return response;
        } else {
          return null;
        }
      }),
      catchError(error => {
        return error;
      })
    );
  }
}
