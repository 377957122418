<div *ngIf="error" class="alert alert-danger alert-dismissible fade show mt-3">
    <i class="uil uil-exclamation-triangle me-2"></i>
    <span class="ms-2">
      {{ error }}
    </span>
    <button type="button" class="btn-close" aria-label="Close" (click)="error = ''"></button>
  </div>
  <div *ngIf="success" class="alert alert-success alert-dismissible contact-alert fade show mt-3 d-flex">
    <i class="uil uil-check me-2"></i>
    <span class="ms-2 contact-desc-alert">
      Hotel description saved successfully
    </span>
    <button type="button" class="btn-close" aria-label="Close" (click)="success = false"></button>
  </div>
<!-- start page title -->
<app-pagetitle title="Hotel Description" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->
<div class="mt-3">
    <p class="hotel-descriptipon-text">{{ 'HOTELDESCRIPTION.GUIDE' | translate }}</p>
    <ul class="hotel-descriptipon-text">
        <li>{{ 'HOTELDESCRIPTION.PRIMARY' | translate }}</li>
        <li>{{ 'HOTELDESCRIPTION.SECONDARY' | translate }}</li>
    </ul>
    <p class="hotel-descriptipon-text">{{ 'HOTELDESCRIPTION.SPECIAL' | translate }}</p>
</div>
<form #descriptionForm="ngForm" (ngSubmit)="onSubmit(descriptionForm)">
    <div class="row mt-3">
        <div class="col-12">
            <div class="card card-h-100">
                <div class="card-body">
                    <div class="">
                            <div class="row">
                              <div class="col-md-12">
                                <label class="primary-desc">Primary hotel description</label>
                              </div>
                            </div>
                    </div>
                    <div *ngFor="let language of languages" class="row mt-2 language-container">
                        <!-- Language Name (Non-editable Text) in col-2 -->
                        <div class="col-1 language-name">
                          {{ language.name }}
                        </div>

                        <!-- Description Textarea in col-10 -->
                        <div class="col-11">
                          <textarea class="form-control form-input-bg language-desc lang-fwt-weight"
                                    name="description_{{ language.code }}"
                                    [(ngModel)]="description[language.code]"
                                    style="height: 200px"
                                    placeholder="{{ language.name }} Hotel description"
                                    [ngClass]="{'is-invalid': descriptionForm.controls['description_' + language.code]?.invalid && descriptionForm.controls['description_' + language.code]?.touched}"
                                    required></textarea>
                        </div>
                    </div>
                </div><!-- end card body -->
            </div><!-- end card -->
        </div><!-- end col -->
        <div class="col-12">
            <div class="card card-h-100">
                <div class="card-body">
                    <div class="">
                            <div class="row">
                              <div class="col-md-12">
                                <label class="secondary-desc">Secondary hotel description</label>
                              </div>
                              <div *ngFor="let language of languages" class="row mt-2 language-container">
                                <!-- Language Name (Non-editable Text) in col-2 -->
                                <div class="col-1 language-name">
                                  {{ language.name }}
                                </div>

                                <!-- Description Textarea in col-10 -->
                                <div class="col-11">
                                  <textarea class="form-control form-input-bg language-desc lang-fwt-weight"
                                            name="secondary_description_{{ language.code }}"
                                            [(ngModel)]="secondary_description[language.code]"
                                            style="height: 200px"
                                            placeholder="{{ language.name }} Hotel description"
                                            [ngClass]="{'is-invalid': descriptionForm.controls['secondary_description_' + language.code]?.invalid && descriptionForm.controls['secondary_description_' + language.code]?.touched}"
                                            required></textarea>
                                </div>
                            </div>
                            </div>
                    </div>
                </div><!-- end card body -->
            </div><!-- end card -->
        </div><!-- end col -->
        <div class="mt-1">
          <button type="submit" class="btn btn-link text-decoration-none cancel_btn w-md">Cancel</button>
          <button type="submit" class="btn btn-primary float-md-end submit_btn submit_btn_border w-md">Save Changes</button>
        </div>
    </div><!-- end row -->
  </form>
