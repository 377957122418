import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FeatureService } from '../../core/services/feature.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-themes',
  templateUrl: './themes.component.html',
  styleUrl: './themes.component.scss'
})
export class ThemesComponent implements OnInit,OnDestroy {
  breadCrumbItems!: Array<{}>;
  public hotelThemes: Array<any> = [];
  public hotelfeatures: Array<any> = [];
  themes: any = {};
  selectAll: boolean = false;
  noThemes: boolean = false;
  success:boolean = false;
  error: string = '';
  private refreshSubscription!: Subscription;

  constructor(
    private featureService: FeatureService
  ) { }

  ngOnInit(): void {
    this.initializeBreadcrumbs();
    this.subscribeToSelectedThemes();
    this.checkAllThemesStatus();
  }

  ngOnDestroy(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }
  initializeBreadcrumbs(): void {
    this.breadCrumbItems = [
      { label: 'Hotel' },
      { label: 'Themes', active: true}
    ];
  }

  subscribeToSelectedThemes(): void {
    this.featureService.getHotelThemes().subscribe((result: any) => {
      this.hotelThemes = result.data;
      this.fetchThemes();
    });
  }

  fetchThemes(): void {
    this.featureService.getThemes().subscribe((themes: any) => {
      this.themes = themes.data.themes;
      this.themes.forEach((theme:any) => {
        const hotelActivity = this.hotelThemes.find(hotelFeature => hotelFeature.id === theme.id);
        if (hotelActivity) {
          theme.is_active = hotelActivity.is_active;
        }
      });
      this.updateSelectAllStatus();
    });
  }

  checkAllThemesStatus() {
    this.selectAll = this.themes.every((theme:any) => theme.is_active);
  }

  saveThemes() {
    const selectedThemes = this.hotelThemes.filter(theme => theme.is_active);
    let Result = selectedThemes.map(choice => ({ id: choice.id }));
  }

  onSubmit(form: NgForm) {
    if (form.valid) {
      const selectedThemes = this.themes.filter((activity: any) => activity.is_active).map((choice: any) => ([choice.id]));
      const result = {
          "themes": selectedThemes.map(String)
      };
      this.featureService.saveThemes(result).subscribe(
        (response: any) => {
          this.success = true;
          setTimeout(() => {
            this.error = '';
          }, 2000);
          console.log("Success");
        },
        (error) => {
          this.error = 'There was an error saving the themes. Please try again.';
          setTimeout(() => {
            this.error = '';
            this.success = false;
          }, 2000);
          console.error("Failed", error);
        }
      );
      console.log('Form submitted with the following themes:', result);
      form.resetForm();
    } else {
      console.log('Form is invalid');
    }
  }

  toggleSelectAll(): void {
    const newStatus = !this.selectAll;
    this.themes.forEach((theme:any) => theme.is_active = newStatus);
    this.selectAll = newStatus;
  }

  toggleThemeStatus(theme: any): void {
    theme.is_active = !theme.is_active;
    this.updateSelectAllStatus();
  }

  updateSelectAllStatus(): void {
    if (this.themes.length === 0) {
      this.selectAll = false;
    } else {
      this.selectAll = this.themes.every((theme:any) => theme.is_active);
    }
  }
}

