<div *ngIf="error" class="alert alert-danger alert-dismissible fade show mt-3">
  <i class="uil uil-exclamation-triangle me-2"></i>
  <span class="ms-2">{{ error }}</span>
  <button type="button" class="btn-close mt-1" aria-label="Close" (click)="dismissError()"></button>
</div>

<div *ngIf="successMessage" class="alert alert-success alert-dismissible fade show mt-3">
  <i class="uil uil-check me-2"></i>
  <span class="ms-2">{{ successMessage }}</span>
  <button type="button" class="btn-close mt-1" aria-label="Close" (click)="dismissSuccess()"></button>
</div>

<app-pagetitle title="Hotel SEO content" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

<p class="seo-content-desc mt-4">
  This meta title and meta description overwrites the default, automated content.
</p>

<div class="col-12">
    <form (ngSubmit)="onSubmit(seoForm)" #seoForm="ngForm">
      <div class="card card-h-100">
        <div class="card-body card-manager-body">
          <!-- Meta Title Section -->
          <div class="row mb-3 position-relative usp-main">
            <label class="meta-title form-label col-2 mt-3" for="meta-title">Meta title</label>
            <div class="col-10">
              <div class="row">
                <div class="col-md-6" *ngFor="let lang of languages; let idx = index">
                  <div class="mb-3 position-relative">
                    <div class="d-flex mb-2">
                      <label for="language-{{ lang['code'] }}" 
                        class="d-flex align-items-center px-3 py-2 text-room-color form-input-bg w-120 lang-field"
                        [ngClass]="{ 'error-border-label': seoForm.submitted && !metaTitle[lang['code']] }"
                        >
                        {{ lang['code'] | uppercase }}
                      </label>
                      <div class="textarea-container">
                        <textarea #textareaTitleElement id="metaTitle{{ lang['code'] }}" [(ngModel)]="metaTitle[lang['code']]"
                          name="metaTitle{{ lang['code'] }}" class="form-control usp form-input-bg text-room-desc"
                          maxlength="60"
                          [ngClass]="{ 'error-border-input': seoForm.submitted && !metaTitle[lang['code']] }"
                          (input)="onTitleInput($event)">
                        </textarea>
                      </div>
                      <div class="count-label"
                      [ngClass]="{ 'error-border-count': seoForm.submitted && !metaTitle[lang['code']] }"
                      >
                        {{ (metaTitle[lang['code']]?.length || 0) }}/60
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card card-h-100">
        <div class="card-body card-manager-body">

          <!-- Meta Description Section -->
          <div class="row mb-3 position-relative usp-main">
            <label class="meta-description form-label mt-3 col-2" for="meta-description">Meta description</label>
            <div class="col-10">
              <div class="row">
                <div class="col-md-6" *ngFor="let lang of languages; let i = index">
                  <div class="mb-3 position-relative">
                    <div class="d-flex mb-2">
                      <label for="language-desc-{{ lang['code'] }}" 
                        class="d-flex align-items-center py-2 form-control text-room-color form-input-bg w-120 lang-field"
                        [ngClass]="{ 'error-border-label': seoForm.submitted && !metaDescription[lang['code']] }"
                        >
                        {{ lang['code'] | uppercase }}
                      </label>
                      <div class="textarea-container">
                        <textarea #textareaElement id="language-desc-{{ lang['code'] }}" [(ngModel)]="metaDescription[lang['code']]"
                          name="metaDescription{{ lang['code'] }}" class="form-control usp form-input-bg text-room-desc"
                          maxlength="160" 
                          [ngClass]="{ 'error-border-input': seoForm.submitted && !metaDescription[lang['code']] }"
                          (input)="onInput($event)">
                        </textarea>
                      </div>
                      <div class="count-label"
                        [ngClass]="{ 'error-border-count': seoForm.submitted && !metaDescription[lang['code']] }"
                      >
                        {{ (metaDescription[lang['code']]?.length || 0) }}/160
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <button type="submit" class="btn btn-link text-decoration-none cancel_btn float-end cancel_btn_hotel_managers w-md">Cancel</button>
        <button type="submit" class="btn btn-primary float-md-end submit_btn submit_btn_border submit-manager-btn position-absolute w-md d-flex justify-content-center align-items-center">Save changes</button>
      </div>
    </form>
</div>
