import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RoomService } from '../../../core/services/room.service';
import { HotelService } from '../../../core/services/hotel.service';
import { FeatureService } from '../../../core/services/feature.service';
import { Hotel } from '../../../core/interfaces/hotel';
import { NgForm } from '@angular/forms';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-room-edit',
  templateUrl: './room-edit.component.html',
  // imports: [CommonModule, FormsModule, ReactiveFormsModule, HttpClientModule],
  styleUrl: './room-edit.component.scss'
})
export class RoomEditComponent implements OnInit {
  breadCrumbItems!: Array<{label: string; link?: string; active?: boolean}>;
  roomId: any;
  public room: any;
  public hotelAmenities: Array<any> = [];
  selectAll:boolean = false;
  amenities: Array<any> = [];
  hotel: any = {};
  success: string = '';
  error: string = '';
  hotelCurrency: string = '';
  images : any = [];
  imageSrc: any = [];
  selectedFile: any = [];

  myForm = new FormGroup({
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private roomService: RoomService,
    private hotelService: HotelService,
    private featureService: FeatureService,
    private sanitizer: DomSanitizer,
  ) {
    this.initializeBreadcrumbs();
  }
  initializeBreadcrumbs(): void {
    this.breadCrumbItems = [
      { label: 'Rooms',link: '/rooms' },
      { label: 'Edit room', active: true}
    ];
  }
  ngOnInit(): void {
    this.roomId = this.route.snapshot.paramMap.get('id');
    this.fetchRoomDetails(this.roomId);
    this.checkAllActivitiesStatus();
    this.subscribeToSelectedHotel();
  }

  subscribeToSelectedHotel(): void {
    this.hotelService.selectedHotel$.subscribe((hotel: Hotel | null) => {
      if (hotel) {
        this.hotel = hotel;
        this.hotelCurrency = this.hotel.currency;
      }
    });
    this.fetchActivities();
  }
  fetchRoomDetails(id: number): void {
    this.roomService.getRoomDetails(id).subscribe((response) => {
      if (response) {
        this.room = response.data;
        this.hotelAmenities = this.room.features;
      }
    });
  }
  fetchActivities(): void {
    this.featureService.getFeatures().subscribe(amenities => {
      this.amenities = amenities.data.amenities;
      this.amenities.forEach(amenity => {
        const hotelActivity = this.hotelAmenities.find(hotelFeature => hotelFeature.id === amenity.id);
        if (hotelActivity) {
          amenity.is_active = hotelActivity.is_active;
        }
      });
      this.updateSelectAllStatus();
    });
  }
  checkAllActivitiesStatus() {
    this.selectAll = this.amenities.every(amenity => amenity.is_active);
  }

  get f(){
    return this.myForm.controls;
  }

  public onFileSelect(event: any) {
    // const reader = new FileReader();
    if(event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      //  reader.readAsDataURL(file);
      //  const img = document.createElement('img');
      //  this.selectedFile = (file: any) => {
      //     reader.readAsDataURL(file);
      //     reader.onload = () => {
      //         img.src = reader.result as string;
      //     };
      //   }
      const payload = {
        images: file
      }
      this.roomService.imageUpload(this.room.id, payload).subscribe(
        (response: any) => {
          if(response.data) {
            setTimeout(() => {
              this.success = 'The image saved successfuly';
              this.error = '';
            }, 100);
          } else {
              setTimeout(() => {
              this.error = 'There was an error while saving the image. Please try again.';
              this.success = '';
              }, 100);
          }
        });
    }
  }

  onSubmit(form: NgForm) {
    if (form.valid) {
      const data = form.value;
      const name = { 'de': data.name_de, 'en':data.name_en, 'fr': data.name_fr, 'it':data.name_it};
      const description = { 'de': data.description_de, 'en':data.description_en, 'fr': data.description_fr, 'it':data.description_it};
      const selectedActivities = this.amenities.filter(amenity => amenity.is_active).map(choice => ([choice.id]));
      const features = selectedActivities.map(String);
      const payload = {
        description: description,
        average_room_size: data.average_room_size,
        name: name,
        features: features,
        currency: this.hotelCurrency,
        amount: data.amount,
        max_people_allowed: data.max_people_allowed,
        is_family_room: data.is_family_room ? 1 : 0,
        max_extra_bed_allowed: data.max_extra_bed_allowed,
        base_price: data.base_price,
        is_breakfast_included: data.is_breakfast_included ? 1: 0,
        allow_single_use: data.allow_single_use ,
        hide: data.hide ? 1 : 0,
        images: [this.myForm.value,  this.room.id],
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      }

      this.roomService.editRoom(this.room.id, payload).subscribe(
        (response: any) => {
          if(response.data) {
            setTimeout(() => {
              this.success = 'The room saved successfuly';
              this.error = '';
            }, 100);
          } else {
              setTimeout(() => {
              this.error = 'There was an error while saving the room. Please try again.';
              this.success = '';
              }, 100);
          }
        });
    } else {
      setTimeout(() => {
        this.error = 'Form is invalid';
        this.success = '';
        }, 100);
    }
  }
  toggleSelectAll(): void {
    const newStatus = !this.selectAll;
    this.amenities.forEach(amenity => amenity.is_active = newStatus);
    this.selectAll = newStatus;
  }

  toggleActivityStatus(amenity: any): void {
    amenity.is_active = !amenity.is_active;
    this.updateSelectAllStatus();
  }

  updateSelectAllStatus(): void {
    if (this.amenities.length === 0) {
      this.selectAll = false;
    } else {
      this.selectAll = this.amenities.every(amenity => amenity.is_active);
    }
  }
}
