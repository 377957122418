import { Component, OnInit } from '@angular/core';
import { Hotel } from '../../core/interfaces/hotel';
import { HotelService } from '../../core/services/hotel.service';
import { DropzoneConfigInterface } from "ngx-dropzone-wrapper";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { GalleryService } from '../../core/services/gallery.service';

@Component({
  selector: 'app-hotel-gallery',
  templateUrl: './hotel-gallery.component.html',
  styleUrl: './hotel-gallery.component.scss'
})
export class HotelGalleryComponent implements OnInit{
  // bread crumb items
  breadCrumbItems!: Array<{}>;
  public gallery: any = {};
  galleryFilter = 'all';
  public imageGrid: any = {};
  public config: DropzoneConfigInterface = {
    clickable: true,
    autoReset: null,
    errorReset: null,
    // Set max files, accepted file types, etc.
    maxFiles: 10,
    acceptedFiles: 'image/*'
  };
  event: any;
  // Temporary storage for uploaded files
  private uploadedImages: any[] = [];
  seasons = ['general', 'summer', 'winter'];
  mainImageId: number | null = null; // ID of the currently selected main image
  private cardIndexToDelete: number | null = null;
  private index: any = {};

  constructor (
    private modalService: NgbModal,
    private galleryService: GalleryService) {
  }

  ngOnInit(): void {
    this.galleryService.getGallery().subscribe(gallery => {
      this.gallery = gallery.data;
      if (this.gallery) {
        this.imageGrid = this.gallery;
        if (this.imageGrid.length > 0) {
          this.mainImageId = this.imageGrid[0].id;
        }
      }
    });
    /**
     * BreadCrumb
     */
    this.breadCrumbItems = [
      { label: 'Hotels' },
      { label: 'Hotel gallery', active: true }
    ];
  }
  /***
   * Active all Products Grid selected
   */
  activeCategory(category: string): void {
    this.galleryFilter = category;
    if (this.galleryFilter === 'all') {
      this.imageGrid = this.gallery;
    } else {
      this.imageGrid = this.gallery.filter((x: any) => x.season_image === this.galleryFilter);
    }
  }

  // Method to open the delete confirmation modal
  openDeleteModal(deleteImageModal:any, index: number): void {
    this.cardIndexToDelete = deleteImageModal;
    this.modalService.open(deleteImageModal, { backdrop: 'static', keyboard: false, centered: true, windowClass: 'deleteModalOpen' });
    this.index = index;
    console.log(index);
  }

  // Method to confirm the deletion of a card
  confirmDelete(modal: any): void {
    if (this.gallery && this.gallery.length > 0) {
      if (this.cardIndexToDelete !== null) {
        const imageIdToDelete = this.gallery[this.index].id;
  
        // Call delete service and then update the UI only if it's successful
        this.galleryService.deleteGallery(imageIdToDelete).subscribe(
          (result1) => {
            // If deletion is successful, update the imageGrid
            this.imageGrid.splice(this.index, 1);
          },
          (error) => {
            console.error('Failed to delete image:', error);
            // Optionally show a message to the user about the failure
          }
        );
      }
      this.cardIndexToDelete = null;
      modal.close();
    }
  }
  

  //  onMainImageToggle(imageId: number): void {
  //   this.mainImageId = this.mainImageId === imageId ? null : imageId;
  // }

  onMainImageToggle(event: any, index: number) {
    if (event.checked) {
      this.gallery.images[index].is_main = 1;
    } else {
      this.gallery.images[index].is_main = 0;
    }
    const season = this.gallery.images[index].season_image;
    this.gallery.images.forEach((image:any, imageId: number) => {
      if (imageId !== index && season === 'general') {
        image.is_main = 0;
      }
      if (imageId !== index && (season === 'summer' || season === 'winter') && image.season_image === 'general') {
        image.is_main = 0;
      }
      if (imageId !== index && image.season_image === season) {
        image.is_main = 0;
      }
    });
  }

  onGalleryDrop(event: CdkDragDrop<any[]>): void {
    const previousIndex = this.imageGrid.findIndex((image:any) => image === event.item.data);
    const currentIndex = event.currentIndex;
    const movedItem = this.imageGrid[previousIndex];

    // Remove the item from its previous position
    this.imageGrid.splice(previousIndex, 1);

    // Insert the item at its new position
    this.imageGrid.splice(currentIndex, 0, movedItem);
  }

  onSeasonabilityChange(index: number, imageId: number, newSeasonImage: string): void {
    this.imageGrid[index].season_image = newSeasonImage;
  }
  onFilesUploadSuccess(event: any): void {
    // Store the uploaded files temporarily until the "Send Files" button is clicked
    const uploadedFiles = event[0].files;
    this.uploadedImages = uploadedFiles.map((file: any) => ({
      name: file.name,
      file: file,  // Save the file object for later use
      url: '',     // Will be updated after sending
      season_image: 'general',  // Adjust as necessary
      is_main: 0   // Adjust as necessary
    }));

    console.log('Files uploaded temporarily:', this.uploadedImages);
  }

  // Handle file upload error
  onFilesUploadError(event: any): void {
    console.error('Error uploading files:', event);
  }

  // Trigger saving the files when "Send Files" is clicked
  sendFiles(): void {
    if (this.uploadedImages.length === 0) {
      console.log('No files to send.');
      return;
    }

    // Now, process the uploaded files and update URLs with the correct file ID
    const newImages = this.uploadedImages.map((image: any) => {
      if (image.file.id) {
        image.url = `https://staging.hotelcard.com/api/v2/hotels/images/${image.file.id}`;
        return image;
      } else {
        console.error('File ID missing for image:', image);
        return null;
      }
    }).filter((image: any) => image !== null);

    // Save the gallery with the new images
    this.galleryService.saveGallery(newImages).subscribe(
      (response) => {
        console.log('Gallery saved successfully:', response);
        this.galleryService.getGallery().subscribe(gallery => {
          this.gallery = gallery.data;
          this.imageGrid = this.gallery;
        });
      },
      (error) => {
        console.error('Failed to save gallery after upload:', error);
      }
    );
  }
  
}
