import { Component } from '@angular/core';
import { HotelService } from '../../core/services/hotel.service';
import { Hotel } from '../../core/interfaces/hotel';
import { NgForm } from '@angular/forms';
import { HotelSettingService } from '../../core/services/hotel-setting.service';

interface Amenity {
  id: number;
  name: string;
  is_active: boolean;
}

interface Category {
  name: string;
  value: Amenity[];
}

@Component({
  selector: 'app-hotel-settings',
  templateUrl: './hotel-settings.component.html',
  styleUrl: './hotel-settings.component.scss'
})
export class HotelSettingsComponent {
  breadCrumbItems!: Array<{}>;
  public hotel: any = {};
  public settings: any = {};
  successMessage: string = '';
  error: string = '';
  notes: { text: string }[] = [];
  labels: Array<any> = [];
  hotelLabels: Array<any> = [];
  dayPeriods: Array<any> = [];
  cm: any;
  cards: any[] = [{}];
  isMinimumLengthActive: boolean = false;

  constructor(
    private hotelService: HotelService,
    private hotelSetting: HotelSettingService
  ){}

  ngOnInit(): void {
    this.initializeBreadcrumbs();
    this.subscribeToHotelSettings();
    this.subscribeToCm();
    // Initializing the days of the week
    this.dayPeriods = [
      { name: 'Monday', is_active: true },
      { name: 'Tuesday', is_active: true },
      { name: 'Wednesday', is_active: true },
      { name: 'Thursday', is_active: true },
      { name: 'Friday', is_active: true },
      { name: 'Saturday', is_active: false },
      { name: 'Sunday', is_active: false }
    ];
  }

  initializeBreadcrumbs(): void {
    this.breadCrumbItems = [
      { label: 'Hotels' },
      { label: 'Hotel settings', active: true }
    ];
  }

  subscribeToHotelSettings(): void {
    this.hotelSetting.getHotelSettings().subscribe(result => {
      if (result) {
        this.settings = result.data;
        this.hotelLabels = this.settings.labels;
        this.subscribeToLables();
      }
    });
  }
  subscribeToLables(): void {
    this.hotelSetting.getLabelsList().subscribe(result => {
      this.labels = result.data;
        this.labels.forEach((response:any) => {
          const activity = this.hotelLabels.find(labels => labels.id === response.id);
          if (activity) {
            response['is_active'] = 'true';
          }
        });
    });
  }

  subscribeToCm(): void {
    this.hotelSetting.getChannelManagersList().subscribe(result => {
      this.cm = result.data;
      console.log(this.cm);
    });
  }

  toggleLabelStatus(label: any): void {
    label.is_active = !label.is_active;
  }

  // Display a success message
  showSuccess(message: string): void {
    this.successMessage = message;
    setTimeout(() => this.dismissSuccess(), 2000);
  }

  // Dismiss success message
  dismissSuccess(): void {
    this.successMessage = '';
  }
  // Display an error message
  showError(message: string): void {
    this.error = message;
    setTimeout(() => this.dismissError(), 2000);
  }

  // Dismiss error message
  dismissError(): void {
    this.error = '';
  }
  onSubmit(form: NgForm) {
    console.log("Submitted",form);
  }
  // Add a new note
  addNote() {
    this.notes.push({ text: '' });
  }

  // Remove a note by index
  removeNote(index: number) {
    this.notes.splice(index, 1);
  }
  toggleAmenityStatus(amenity: Amenity, category: Category): void {
    amenity.is_active = !amenity.is_active;
    // You can add additional logic here if needed
  }

  // Method to add a new card
  addCard() {
    // Push a new card object into the cards array
    this.cards.push({});
  }

  // Example toggle method for handling checkbox changes
  toggleLabelSettingsStatus(dayPeriod: any) {
    // Logic to toggle the status
    dayPeriod.is_active = !dayPeriod.is_active;
  }
  focusInput(dateInput: HTMLInputElement): void {
    dateInput.focus();
  }
}
