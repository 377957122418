import { Component, OnInit } from '@angular/core';
import { HotelService } from '../../core/services/hotel.service';
import { Hotel } from '../../core/interfaces/hotel';
import { ContactInformationService } from '../../core/services/contact-information.service';
import { NgForm } from '@angular/forms';
import { CONTACT } from './contact';
import { ContactInfo } from '../../core/interfaces/contact-info';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from '../../core/services/language.service';

@Component({
  selector: 'app-contact-information',
  templateUrl: './contact-information.component.html',
  styleUrl: './contact-information.component.scss'
})
export class ContactInformationComponent implements OnInit {
breadCrumbItems!: Array<{}>;
maleTranslation: string = '';
femaleTranslation: string = '';
noBookingRequestsLabel: string = '';
public hotel: any = {};
public hotel_contact: any = {};
radioOptions: any = {};
contactInfo: ContactInfo[] = [];
success:boolean = false;
error: string = '';
languages = [
  { id: 1, code: 'de', name: 'Deutsch' },
  { id: 2, code: 'en', name: 'English' },
  { id: 3, code: 'fr', name: 'Français' },
  { id: 4, code: 'it', name: 'Italiano' }
];
constructor(private hotelService: HotelService,
            private contactInformationService: ContactInformationService,
            public translate: TranslateService,
            public cookieService: CookieService,
            private languageService: LanguageService ) {

  const lang = this.cookieService.get('selectedLanguage') || 'en';
  this.languageService.setLanguage(lang); // Set the initial language
  this.translate.setDefaultLang(lang);
  this.translate.use(lang);
  this.contactInfo = CONTACT;
  this.radioOptions = this.contactInfo
  .sort((a, b) => {
    if (a.label === 'CONTACTINFO.FEMALE') {
      return -1;
    } else if (b.label === 'CONTACTINFO.FEMALE') {
      return 1;
    }
    return 0
  })
  .map((contact) => ({
    id: contact.id,
    gender: contact.label === 'CONTACTINFO.MALE' ? 'male' : 'female',
    label: contact.label,
    no_booking_requests: contact.no_booking_requests === 'CONTACTINFO.NO_BOOKING_REQUESTS'
      ? this.translate.instant('CONTACTINFO.NO_BOOKING_REQUESTS')
      : ''
  }));

  // Subscribe to language changes
  this.languageService.currentLang$.subscribe((lang) => {
    this.translate.use(lang);
    this.updateTranslations();
  });
}
ngOnInit(): void {
    this.subscribeToSelectedHotel();
    this.subscribeToSelectedContact();
    this.initiliazeBreadCrumbs();
  }
  updateTranslations() {
    this.maleTranslation = this.translate.instant('CONTACTINFO.MALE');
    this.femaleTranslation = this.translate.instant('CONTACTINFO.FEMALE');
    this.noBookingRequestsLabel = this.translate.instant('CONTACTINFO.NO_BOOKING_REQUESTS');
  }
  initiliazeBreadCrumbs(){
   /**
   * BreadCrumb
   */
    this.breadCrumbItems = [
      { label: 'Hotels' },
      { label: 'Contact information', active: true }
    ];
  }
  subscribeToSelectedHotel(): void {
    this.hotelService.selectedHotel$.subscribe((hotel: Hotel | null) => {
      if (hotel) {
        this.hotel = hotel;
      }
    });
  }
  subscribeToSelectedContact(): void{
    this.contactInformationService.getHotelContact().subscribe((contact) => {
      if (contact) {
        this.hotel_contact = contact.data;
      }
    });
  }
  onSubmit(form: NgForm) {
    if (form.valid) {
      const payload = {
          gender: form.value.gender,
          first_name: form.value.first_name,
          last_name: form.value.last_name,
          role: form.value.role,
          email: form.value.email,
          general_email: form.value.general_email,
          booking_email: form.value.booking_email,
          phone_number: form.value.phone_number,
          hotel_phone_number: form.value.number,
          phone_requests: form.value.phone_requests,
          booking_language_id: form.value.booking_language_id,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
      };
      this.contactInformationService.saveHotelContact(payload).subscribe(
        (response: any) => {
          this.success = true;
          setTimeout(() => {
            this.error = '';
          }, 2000);
          console.log("Success");
        },
        (error) => {
          this.error = 'There was an error saving the contact information. Please try again.';
          setTimeout(() => {
            this.error = '';
            this.success = false;
          }, 2000);
          console.error("Failed", error);
        }
      );
    } else {
      this.error = 'Please fill out all required fields.';
      setTimeout(() => {
        this.error = '';
        this.success = false;
      }, 2000);
      console.log('Form is invalid');
    }
  }
    onHideChange(hotel: any, event: Event) {
      const checkbox = event.target as HTMLInputElement;
      if (checkbox.checked) {
        console.log(`Hotel Contact ${hotel.name} is now hidden.`);
        hotel.phone_requests = 1;
        console.log(`Hotel Contact ${hotel.name} is now visible.`);
        hotel.phone_requests = 0;
      }
    }

    isPhoneRequestsHidden(room: any): boolean {
      return room.phone_requests === 1;
    }
}
