<div *ngIf="error" class="alert alert-danger alert-dismissible fade show mt-3">
  <i class="uil uil-exclamation-triangle me-2"></i>
  <span class="ms-2">
    {{ error }}
  </span>
  <button type="button" class="btn-close" aria-label="Close" (click)="error = ''"></button>
</div>
<div *ngIf="success" class="alert alert-success alert-dismissible fade show mt-3">
  <i class="uil uil-check me-2"></i>
  <span class="ms-2">
    {{ success }}
  </span>
  <button type="button" class="btn-close" aria-label="Close" (click)="success = ''"></button>
</div>
<div class="container-fluid mt-3">
    <!-- start page title -->
    <div class="d-flex justify-content-between align-items-center">
        <app-pagetitle title="Add Room"></app-pagetitle>
        <!-- Breadcrumb navigation positioned to the right -->
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb justify-content-end mb-0">
            <li *ngFor="let item of breadCrumbItems; let last = last" class="breadcrumb-item" [ngClass]="{ 'active': item.active }">
              <ng-container *ngIf="!last">
                <!-- Non-active breadcrumb items with routerLink -->
                <a [routerLink]="item.link">{{ item.label }}</a>
              </ng-container>
              <ng-container *ngIf="last">
                <!-- Active breadcrumb (the last item) with no link -->
                {{ item.label }}
              </ng-container>
            </li>
          </ol>
        </nav>
      </div>
    <!-- end page title -->
    <div class="card mt-2">
        <div class="card-body">
            <form [formGroup]="roomAddForm" (ngSubmit)="onSubmit()">
                <div class="row mb-3">
                    <label for="roomName" class="form-label col-2">Room Description</label>
                    <div class="col-8">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <div class="d-flex mb-2">
                                        <span class="px-3 py-2 text-room-color form-input-bg">Duetsch</span><input type="email" class="form-control form-input-bg text-room-desc" formControlName="name_de" required>
                                    </div>
                                    <textarea class="form-control form-input-bg textarea-room"
                                        formControlName="description_de"
                                        placeholder="Room description"
                                        required minlength="4">
                                    </textarea>
                                  <div *ngIf="f['description_de'].invalid && (f['description_de'].dirty || f['description_de'].touched)" class="text-danger">
                                    <div *ngIf="f['description_de'].errors?.['required']">Hotel description in Duetsch is required.</div>
                                  </div>
                                  <div *ngIf="f['name_de'].invalid && (f['name_de'].dirty || f['name_de'].touched)" class="text-danger">
                                    <div *ngIf="f['name_de'].errors?.['required']">Hotel name in Duetsch is required.</div>
                                  </div>
                                </div>
                            </div><!-- end col -->
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <div class="d-flex">
                                        <span class="px-3 py-2 text-room-color form-input-bg">Francais</span><input type="email" class="form-control form-input-bg text-room-desc" formControlName="name_fr" required>
                                    </div>
                                    <textarea class="form-control form-input-bg textarea-room"
                                            formControlName="description_fr"
                                            placeholder="Room description"
                                            required>
                                    </textarea>
                                    <div *ngIf="f['description_fr'].invalid && (f['description_fr'].dirty || f['description_fr'].touched)" class="text-danger">
                                      <div *ngIf="f['description_fr'].errors?.['required']">Hotel description in Francais is required.</div>
                                    </div>
                                    <div *ngIf="f['name_fr'].invalid && (f['name_fr'].dirty || f['name_fr'].touched)" class="text-danger">
                                      <div *ngIf="f['name_fr'].errors?.['required']">Hotel name in Francais is required.</div>
                                    </div>
                                </div>
                            </div><!-- end col -->
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <div class="d-flex">
                                        <span class="px-3 py-2 text-room-color form-input-bg">English</span><input type="email" class="form-control form-input-bg text-room-desc" formControlName="name_en" required>
                                    </div>
                                    <textarea class="form-control form-input-bg textarea-room"
                                        formControlName="description_en"
                                        placeholder="Room description"
                                        required>
                                    </textarea>
                                    <div *ngIf="f['description_en'].invalid && (f['description_en'].dirty || f['description_en'].touched)" class="text-danger">
                                      <div *ngIf="f['description_en'].errors?.['required']">Hotel description in English is required.</div>
                                    </div>
                                    <div *ngIf="f['name_en'].invalid && (f['name_en'].dirty || f['name_en'].touched)" class="text-danger">
                                      <div *ngIf="f['name_en'].errors?.['required']">Hotel name in English is required.</div>
                                    </div>
                                </div>
                            </div><!-- end col -->
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <div class="d-flex">
                                        <span class="px-3 py-2 text-room-color form-input-bg">Italiano</span><input type="email" class="form-control form-input-bg text-room-desc" formControlName="name_it" required>
                                    </div>
                                    <textarea class="form-control form-input-bg textarea-room"
                                        formControlName="description_it"
                                        placeholder="Room description"
                                        required>
                                    </textarea>
                                    <div *ngIf="f['description_it'].invalid && (f['description_it'].dirty || f['description_it'].touched)" class="text-danger">
                                      <div *ngIf="f['description_it'].errors?.['required']">Hotel description in Italiano is required.</div>
                                    </div>
                                    <div *ngIf="f['name_it'].invalid && (f['name_it'].dirty || f['name_it'].touched)" class="text-danger">
                                      <div *ngIf="f['name_it'].errors?.['required']">Hotel name in Italiano is required.</div>
                                    </div>
                                </div>
                            </div><!-- end col -->
                        </div><!-- end row -->
                    </div>
                    <div class="col-2">
                        <button type="button" class="btn btn-link p-0">
                            <i class="fas fa-trash-alt delete-icon-danger"></i> <!-- Red delete icon -->
                        </button>
                        <img src="assets/images/drag.png" alt="drag-icon" class="img-fluid ms-2">
                    </div>
                </div>
                <hr>
                <div class="row mb-3">
                    <label for="roomName" class="form-label col-2">Room Size</label>
                    <div class="col-10">
                      <input type="email" class="form-control form-input-bg text-room-desc text-room-size"
                      formControlName="average_room_size"
                      value="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; m²"
                      required
                      >
                    </div>
                    <div *ngIf="f['name_it'].invalid && (f['name_it'].dirty || f['name_it'].touched)" class="text-danger">
                      <div *ngIf="f['name_it'].errors?.['required']">Hotel name in Italiano is required.</div>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-2">
                      <h4 class="card-title">Amenities</h4>
                    </div>
                    <div class="col-10">
                      <div class="row">
                        <div class="col-auto">
                          <div class="form-check text-room-desc form-room-check" id="categories-pill">
                            <input
                              class="form-check-input cursor-pointer"
                              type="checkbox"
                              [id]="'checkAll'"
                              (change)="toggleSelectAll()"
                              [checked]="selectAll"
                              [ngClass]="{'active': selectAll, 'inactive': !selectAll}"
                              style="display: none;">
                            <label
                              class="form-check-label cursor-pointer categories categories-pill"
                              [for]="'checkAll'"
                              [ngClass]="{'active': selectAll, 'inactive': !selectAll}"
                            >
                              Select all
                            </label>
                          </div>
                        </div>

                        <div class="col-12 col-md-10">
                          <div class="row">
                            <div class="col-auto" *ngFor="let amenities of hotelAmenities; let index = index;">
                              <div class="form-check text-room-desc form-room-check" id="categories-pill">
                                <input
                                  class="form-check-input cursor-pointer"
                                  type="checkbox"
                                  [id]="'check' + index"
                                  [(checked)]="amenities.is_active"
                                  (change)="toggleActivityStatus(amenities)"
                                  [ngClass]="{'active': amenities.is_active, 'inactive': !amenities.is_active}"
                                  style="display: none;"
                                  formControlName="amenities" required>

                                <label
                                  class="form-check-label cursor-pointer categories"
                                  [ngClass]="{'active': amenities.is_active, 'inactive': !amenities.is_active}"
                                  [for]="'check' + index">
                                  {{ amenities.name }}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="f['amenities'].invalid && (f['amenities'].dirty || f['amenities'].touched)" class="text-danger">
                          <div *ngIf="f['amenities'].errors?.['required']">Select any 3 amenities are required.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                <hr>
                <div class="row mb-3">
                    <label for="roomName" class="form-label col-2">Availability</label>
                    <div class="col-10">
                        <span class="text-room-color">Rooms per day on HotelCard</span>
                        <span class="d-flex">
                            <input class="form-control form-input-bg availability-desc cursor-pointer" type="number" formControlName="amount" required>
                        </span>
                    </div>
                </div>
                <div *ngIf="f['amount'].invalid && (f['amount'].dirty || f['amount'].touched)" class="text-danger">
                  <div *ngIf="f['amount'].errors?.['required']">Room amount is required.</div>
                </div>
                <hr>
                <div class="row mb-3">
                    <label for="roomName" class="form-label col-2">Occupancy</label>
                    <div class="col-10">
                        <span class="text-room-color">Max no of adults</span>
                        <span class="d-flex">
                            <input class="form-control form-input-bg occupancy-desc cursor-pointer" type="number" formControlName="max_people_allowed" required>
                            <div class="form-check text-room-desc ms-5">
                                <input class="form-check-input cursor-pointer" type="checkbox" formControlName="is_family_room">
                                <label class="form-check-label cursor-pointer" for="autoSizingCheckFamily">
                                    Family Room
                                </label>
                            </div>
                        </span>
                    </div>
                </div>
                <div *ngIf="f['max_people_allowed'].invalid && (f['max_people_allowed'].dirty || f['max_people_allowed'].touched)" class="text-danger">
                  <div *ngIf="f['max_people_allowed'].errors?.['required']">No of adults is required.</div>
                </div>
                <hr>
                <div class="row mb-3">
                    <label for="roomName" class="form-label col-2">Extra Beds</label>
                    <div class="col-10">
                        <select class="form-select extra-bed form-input-bg text-room-desc cursor-pointer" formControlName="extra_bed_allowed">
                            <option>Select the bed</option>
                            <option>1</option>
                            <option>2</option>
                        </select>
                    </div>
                </div>
                <hr>
                <div class="row mb-3">
                    <label for="roomName" class="form-label col-2">Base Price</label>
                    <div class="col-10">
                        <input type="email" class="form-control form-input-bg text-room-desc text-room-size" formControlName="base_price" required>
                        <div class="form-check form-switch form-switch-md mb-2 mt-1 text-room-desc" dir="ltr">
                            <input type="checkbox" class="form-check-input cursor-pointer" formControlName="is_breakfast_included">
                            <label class="form-check-label cursor-pointer label-switch-text position-relative" for="customSwitchsizemd">Breakfast included</label>
                        </div>
                        <div class="form-check text-room-desc">
                            <input class="form-check-input cursor-pointer" type="checkbox" formControlName="allow_single_use">
                            <label class="form-check-label cursor-pointer" for="formCheck1">
                                Single use
                            </label>
                        </div>
                    </div>
                </div>
                <div *ngIf="f['base_price'].invalid && (f['base_price'].dirty || f['base_price'].touched)" class="text-danger">
                  <div *ngIf="f['base_price'].errors?.['required']">Room base price is required.</div>
                </div>
                <hr>
                <div class="row mb-3">
                    <label for="roomName" class="form-label col-2" >Room images</label>
                    <div class="col-10">
                        <div>
                            <dropzone class="dropzone" formControlName="images" (change)="onFileSelected($event)" name="images"></dropzone>
                        </div>
                    </div>
                </div>
                <div class="form-check form-switch form-switch-md mb-2 mt-1 text-room-desc position-absolute hide-room-switch" dir="ltr">
                    <label class="form-check-label mt-1 cursor-pointer label-switch-text position-relative" for="customHideSwitchsizemd">Hide room</label>
                    <input type="checkbox" class="form-check-input position-relative form-hide-switch cursor-pointer" formControlName="hide">
                </div>
                <button type="submit" class="btn btn-primary submit_btn float-md-end cursor-pointer" [disabled]="!roomAddForm.valid">Add room details</button>

            </form>
        </div><!-- end card-body -->
    </div><!-- end card -->
  </div>
